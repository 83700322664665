import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Footer } from '@app/components/mol.footer/footer.component';
import { Header } from '@app/components/mol.header';
import { Route, ScrollToTop } from '@app/core/route';
import { DisableAnimationsContext, DisableAnimationsProvider } from '@app/data/storage/disable-animation.context';
import { Routes } from '@app/modules/routes';
import { LandmarksIds } from '@atomic/mol.accessibility';
import { GlobalStyled } from '@atomic/obj.globals';
import { MediaQueryProvider } from '@atomic/obj.media-query/media-query-provider.component';
function App() {
    return (React.createElement(DisableAnimationsProvider, null,
        React.createElement(DisableAnimationsContext.Consumer, null, function (_a) {
            var value = _a.value;
            return (React.createElement(ThemeProvider, { theme: { disableAnimations: value } },
                React.createElement(MediaQueryProvider, null,
                    React.createElement(GlobalStyled, null),
                    React.createElement(Route, null,
                        React.createElement(ScrollToTop, null),
                        React.createElement(Header, null),
                        React.createElement("main", { id: LandmarksIds.Main },
                            React.createElement(Routes, null)),
                        React.createElement(Footer, null)))));
        })));
}
export default App;
