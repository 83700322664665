var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color } from '@atomic/obj.constants';
var nubMargin = '4px';
export var ToggleButtonStyled = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  height: 24px;\n  width: 40px;\n  border: none;\n  border-radius: 12px;\n\n  background: ", ";\n\n  cursor: pointer;\n\n  &[aria-pressed='true'] {\n    background: linear-gradient(90deg, #006996 0%, #3387ab 100%);\n    & > div {\n      left: initial;\n      right: ", ";\n    }\n  }\n"], ["\n  position: relative;\n  height: 24px;\n  width: 40px;\n  border: none;\n  border-radius: 12px;\n\n  background: ", ";\n\n  cursor: pointer;\n\n  &[aria-pressed='true'] {\n    background: linear-gradient(90deg, #006996 0%, #3387ab 100%);\n    & > div {\n      left: initial;\n      right: ", ";\n    }\n  }\n"])), Color.Gray, nubMargin);
export var ToggleButtonNubStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  left: ", ";\n  position: absolute;\n  height: 16px;\n  width: 16px;\n  top: ", ";\n  border-radius: 50%;\n  background: white;\n"], ["\n  left: ", ";\n  position: absolute;\n  height: 16px;\n  width: 16px;\n  top: ", ";\n  border-radius: 50%;\n  background: white;\n"])), nubMargin, nubMargin);
var templateObject_1, templateObject_2;
