var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { InputPlaceholder } from '@atomic/atm.typography';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';
import { FontWeight } from '@atomic/obj.constants';
export var InputLabelChildren = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var InputLabelWrapperStyled = styled(InputPlaceholder)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  left: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n"], ["\n  position: absolute;\n  top: ", ";\n  left: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n"])), Spacing.Large, Spacing.Medium, FontSize.XSmall, FontWeight.Regular, function (props) { return (props.filled ? Color.Primary : Color.Gray); });
export var InputLabelStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  padding-top: ", ";\n"], ["\n  position: relative;\n  padding-top: ", ";\n"])), Spacing.Medium);
var templateObject_1, templateObject_2, templateObject_3;
