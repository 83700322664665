import * as React from 'react';
import { IcArrowRight, IcExternalLink, IcLoader } from '@assets/icons';
import { Hbox } from '@atomic/obj.box';
import { AnchorButtonStyled, ButtonContentStyled, ButtonSpinnerStyled, ButtonStyled, LinkButtonStyled, } from './button.component.style';
var startsWithUrlSchemaRegex = /^.+\:.+/i;
export var Button = React.forwardRef(function (props, ref) {
    var _a;
    var disabled = props.disabled, expanded = props.expanded, loading = props.loading, _b = props.type, type = _b === void 0 ? 'button' : _b, _c = props.variant, variant = _c === void 0 ? 'none' : _c, round = props.round, target = props.target, to = props.to, href = props.href, leftIcon = props.leftIcon;
    var onClick = props.onClick;
    var ButtonComponent = React.useMemo(function () { return (to ? LinkButtonStyled : href ? AnchorButtonStyled : ButtonStyled); }, [to, href]);
    var containerRef = React.useRef(null);
    React.useImperativeHandle(ref, function () { return ({
        focus: function () {
            var _a, _b;
            (_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.children[0]) === null || _b === void 0 ? void 0 : _b.focus();
        },
    }); });
    var isExternalLink = !!((_a = props.href) === null || _a === void 0 ? void 0 : _a.match(startsWithUrlSchemaRegex));
    return (React.createElement("span", { ref: containerRef },
        React.createElement(ButtonComponent, { id: props.id, variant: variant, disabled: loading || disabled, loading: loading, type: !to ? type : undefined, expanded: expanded ? 1 : 0, round: round, target: target, 
            // https://developers.google.com/web/tools/lighthouse/audits/noopener
            rel: target === '_blank' ? 'noopener' : undefined, onClick: onClick, to: to, href: href, "aria-label": props['aria-label'], "aria-expanded": props['aria-expanded'], "aria-current": props['aria-current'] },
            React.createElement(ButtonContentStyled, { variant: variant, round: round, processing: loading },
                React.createElement(Hbox, null,
                    !!leftIcon && (React.createElement(React.Fragment, null,
                        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' }, leftIcon),
                        React.createElement(Hbox.Separator, null))),
                    React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' }, props.children),
                    (!!props.to || (props.href && !isExternalLink)) && variant === 'link' && !props.leftIcon && (React.createElement(React.Fragment, null,
                        React.createElement(Hbox.Separator, null),
                        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                            React.createElement(IcArrowRight, null)))),
                    props.href && isExternalLink && variant === 'link' && !props.leftIcon && (React.createElement(React.Fragment, null,
                        React.createElement(Hbox.Separator, null),
                        React.createElement(Hbox.Item, { vAlign: 'center', noGrow: true },
                            React.createElement(IcExternalLink, null)))))),
            React.createElement(ButtonSpinnerStyled, { variant: variant, round: round, processing: loading },
                React.createElement(IcLoader, null)))));
});
Button.displayName = 'Button';
