import * as React from 'react';
import { VisuallyHidden } from '@atomic/mol.accessibility';
import { CarouselPagerBulletStyled, CarouselPagerItemStyled, CarouselPagerStyled, } from './carousel-pager.component.style';
export var CarouselPager = function (props) {
    return (React.createElement(CarouselPagerStyled, null, new Array(props.total).fill(undefined).map(function (_i, index) { return (React.createElement(CarouselPagerItemStyled, { key: 'name' + index },
        React.createElement(CarouselPagerBulletStyled, { active: index === props.current, onClick: function () { return props.onClick(index); } },
            React.createElement(VisuallyHidden, null,
                "Ir para o item ",
                index + 1,
                React.createElement("span", null, index === props.current ? ' (item atual)' : ''))))); })));
};
