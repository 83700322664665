var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FocusCss } from '@atomic/atm.focus/focus.style';
import { Color, Spacing } from '@atomic/obj.constants';
export var FooterStyled = styled.footer(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: ", ";\n  background-color: ", ";\n  padding-bottom: ", ";\n"], ["\n  padding-top: ", ";\n  background-color: ", ";\n  padding-bottom: ", ";\n"])), Spacing.XSmall, Color.GrayXLight, Spacing.XSmall);
export var FooterItemListStyled = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: ", ";\n  margin-bottom: ", ";\n  padding-inline-start: 0;\n"], ["\n  margin-top: ", ";\n  margin-bottom: ", ";\n  padding-inline-start: 0;\n"])), Spacing.Medium, Spacing.Medium);
export var FooterItemStyled = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  list-style: none;\n"], ["\n  list-style: none;\n"])));
export var FooterAnchorStyled = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &:focus-visible {\n    ", "\n  }\n  & > img {\n    vertical-align: middle;\n  }\n"], ["\n  &:focus-visible {\n    ", "\n  }\n  & > img {\n    vertical-align: middle;\n  }\n"])), FocusCss);
export var FooterItemInlineStyled = styled.li(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  list-style: none;\n  display: inline;\n  margin-right: ", ";\n"], ["\n  list-style: none;\n  display: inline;\n  margin-right: ", ";\n"])), Spacing.Medium);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
