import React from 'react';
import { AppAssets } from '@app/components/atm.app-assets/app-assets';
import { LeftSideBg, RightSideBg } from '@atomic/atm.background/background.styled';
import { ReferenceDivStyled } from '@atomic/atm.position-wrapper/reference-div.component.style';
import { AnimatedImage } from '@atomic/mol.animated-image/animated-image.component';
import { ResponsiveImage } from '@atomic/mol.animated-image/animated-image.component.style';
export var HeroBgMdScreen = function (_a) {
    var children = _a.children;
    return (React.createElement(ReferenceDivStyled, null,
        React.createElement(LeftSideBg, null,
            React.createElement(AnimatedImage, { invertAnimation: true, Image: function () { return React.createElement(ResponsiveImage, { visibilityHidden: true, alt: '', src: AppAssets.Image.BgHalfGreenCircle }); }, Background: function () { return React.createElement(ResponsiveImage, { alt: '', src: AppAssets.Image.BgHalfGreenCircle }); } })),
        React.createElement(RightSideBg, null,
            React.createElement(AnimatedImage, { Image: function () { return React.createElement(ResponsiveImage, { visibilityHidden: true, alt: '', src: AppAssets.Image.BgMultiColorCircles }); }, Background: function () { return React.createElement(ResponsiveImage, { alt: '', src: AppAssets.Image.BgMultiColorCircles }); } })),
        children));
};
