export var footerStrings = {
    contact: {
        title: 'Contato',
        address: 'Avenida Doutor Arnaldo, 2194',
        addressLink: 'https://goo.gl/maps/94UiuqLxk2A2d4Ki9',
        phone: '+55 (11) 5181-5274 - Ramal 4609',
        phoneLink: 'tel:+55 (11) 5181-5274;4609',
        email: 'instituto@taqtile.com.br',
        emailLink: 'mailto:instituto@taqtile.com.br',
    },
    social: {
        title: 'Social',
        instagram: {
            label: 'Instagram',
            link: 'https://www.instagram.com/institutotaqtile/',
        },
        linkedin: {
            label: 'LinkedIn',
            link: 'https://br.linkedin.com/company/instituto-taqtile',
        },
        medium: {
            label: 'Medium',
            link: 'https://medium.com/instituto-taqtile',
        },
        openJobs: {
            label: 'Vagas em aberto',
            link: 'https://taqtile.typeform.com/vagas-instaq',
        },
    },
};
