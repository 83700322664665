import * as React from 'react';
import { NewCarouselInnerStyled, NewCarouselItemStyled } from './new-carousel.component.style';
import { useCarouselAnimation, useCarouselFocus, useCarouselIndex, useCarouselSwipe, useEscapeTaps } from './utils';
export var InnerCarousel = React.forwardRef(function (props, ref) {
    var loop = props.loop, duration = props.duration, minMove = props.minMove, children = props.children, onIndexChanged = props.onIndexChanged;
    var _a = useCarouselIndex({ loop: loop, children: children }), carouselItems = _a.carouselItems, firstIndex = _a.firstIndex, lastIndex = _a.lastIndex, extraLeftIndex = _a.extraLeftIndex, extraRightIndex = _a.extraRightIndex, realToVisualIndexMapper = _a.realToVisualIndexMapper;
    var _b = React.useState(firstIndex), activeIndex = _b[0], setActiveIndex = _b[1];
    var carouselInnerRef = React.useRef(null);
    var isInLoopTransition = React.useRef(false);
    var shouldFocusItem = React.useRef(false);
    var _c = useCarouselFocus({ firstIndex: firstIndex }), itemsRef = _c.itemsRef, showItems = _c.showItems, showOnlyItem = _c.showOnlyItem;
    var _d = useCarouselAnimation({ carouselInnerRef: carouselInnerRef, duration: duration, showItems: showItems }), swipeToIndex = _d.swipeToIndex, sneakToIndex = _d.sneakToIndex;
    var goToIndex = React.useCallback(function (index) {
        var newIndex = index;
        var isGoingToNextItem = index === activeIndex + 1;
        var isGoingToPreviousItem = index === activeIndex - 1;
        if (isGoingToNextItem) {
            if (loop) {
                isInLoopTransition.current = index === extraRightIndex;
                newIndex = index > extraRightIndex ? firstIndex : index;
            }
            else {
                newIndex = index > lastIndex ? lastIndex : index;
            }
        }
        if (isGoingToPreviousItem) {
            if (loop) {
                isInLoopTransition.current = index === extraLeftIndex;
                newIndex = index < extraLeftIndex ? lastIndex : index;
            }
            else {
                newIndex = index < firstIndex ? firstIndex : index;
            }
        }
        onIndexChanged === null || onIndexChanged === void 0 ? void 0 : onIndexChanged(realToVisualIndexMapper(newIndex));
        swipeToIndex(newIndex);
        if (activeIndex !== newIndex) {
            setActiveIndex(newIndex);
        }
    }, [
        activeIndex,
        loop,
        extraLeftIndex,
        extraRightIndex,
        firstIndex,
        lastIndex,
        onIndexChanged,
        swipeToIndex,
        realToVisualIndexMapper,
    ]);
    var goToNextItem = React.useCallback(function () {
        shouldFocusItem.current = false;
        goToIndex(activeIndex + 1);
    }, [activeIndex, goToIndex]);
    var goToPreviousItem = React.useCallback(function () {
        shouldFocusItem.current = false;
        goToIndex(activeIndex - 1);
    }, [activeIndex, goToIndex]);
    useCarouselSwipe({ activeIndex: activeIndex, carouselInnerRef: carouselInnerRef, minMove: minMove, loop: loop, firstIndex: firstIndex, lastIndex: lastIndex, showItems: showItems, goToIndex: goToIndex });
    useEscapeTaps({ carouselInnerRef: carouselInnerRef });
    var fixIndex = function (index) {
        // if loop extra elements are reached, fix index without animation
        isInLoopTransition.current = false;
        sneakToIndex(index);
        setActiveIndex(index);
    };
    var handleTransitionEnd = function () {
        // fix index without animation when loop extra elements are reached
        if (isInLoopTransition.current) {
            var nextActiveIndex = activeIndex === 0 ? carouselItems.length - 2 : 1;
            fixIndex(nextActiveIndex);
            showOnlyItem({ index: nextActiveIndex, withFocus: shouldFocusItem.current });
        }
        else {
            showOnlyItem({ index: activeIndex, withFocus: shouldFocusItem.current });
        }
    };
    var handleTouchDown = function () {
        // fix index if user is trying to reach other item before handleTransitionEnd is called
        if (isInLoopTransition.current) {
            fixIndex(activeIndex === 0 ? carouselItems.length - 2 : 1);
        }
    };
    var goToVisualIndex = function (index) {
        var realIndex = loop ? index + 1 : index;
        shouldFocusItem.current = true;
        goToIndex(realIndex);
    };
    React.useImperativeHandle(ref, function () { return ({
        innerRef: carouselInnerRef,
        goToNext: goToNextItem,
        goToPrevious: goToPreviousItem,
        goToIndex: goToVisualIndex,
    }); });
    return (React.createElement(NewCarouselInnerStyled, { ref: carouselInnerRef, onTouchStart: handleTouchDown, onMouseDown: handleTouchDown, onTransitionEnd: handleTransitionEnd, style: { transform: "translateX(-".concat(firstIndex * 100, "%)") } }, carouselItems.map(function (child, index) { return (React.createElement(NewCarouselItemStyled, { key: index, ref: function (element) { return (itemsRef.current[index] = element); } }, child)); })));
});
InnerCarousel.displayName = 'InnerCarousel';
