import * as React from 'react';
var hasWindow = function () { return typeof window !== 'undefined'; };
export var useEscapeTaps = function (_a) {
    var carouselInnerRef = _a.carouselInnerRef;
    var lastSwipeTimestamp = React.useRef();
    var isTouchingDown = React.useRef(false);
    var startX = React.useRef(null);
    var deltaX = React.useRef(null);
    var handleTouchStart = React.useCallback(function (e) {
        var _a;
        isTouchingDown.current = true;
        var pageX = (((_a = e.touches) === null || _a === void 0 ? void 0 : _a[0]) || e).pageX;
        startX.current = pageX;
        deltaX.current = 0;
    }, []);
    var handleTouchMove = React.useCallback(function (e) {
        var _a, _b;
        if (((_a = e.touches) === null || _a === void 0 ? void 0 : _a.length) > 1 || !isTouchingDown.current) {
            return;
        }
        var pageX = (((_b = e.touches) === null || _b === void 0 ? void 0 : _b[0]) || e).pageX;
        deltaX.current = pageX - startX.current;
    }, []);
    var handleTouchEnd = React.useCallback(function () {
        if (Math.abs(deltaX.current) > 4) {
            lastSwipeTimestamp.current = Date.now();
            isTouchingDown.current = false;
            startX.current = null;
            deltaX.current = null;
        }
    }, []);
    var handleClick = React.useCallback(function (event) {
        var now = Date.now();
        var justFinishedSwipe = now - lastSwipeTimestamp.current < 400;
        if (justFinishedSwipe) {
            event === null || event === void 0 ? void 0 : event.stopImmediatePropagation();
            event === null || event === void 0 ? void 0 : event.preventDefault();
        }
    }, []);
    var handleDragStart = React.useCallback(function (event) {
        // remove drag so swipe works properly
        event === null || event === void 0 ? void 0 : event.preventDefault();
    }, []);
    React.useEffect(function () {
        if (hasWindow()) {
            window.addEventListener('mouseup', handleTouchEnd);
            window.addEventListener('touchend', handleTouchEnd);
        }
        return function () {
            if (hasWindow()) {
                window.removeEventListener('mouseup', handleTouchEnd);
                window.removeEventListener('touchend', handleTouchEnd);
            }
        };
    }, [handleTouchEnd]);
    React.useEffect(function () {
        var carouselInner = carouselInnerRef.current;
        if (carouselInner) {
            carouselInner.addEventListener('click', handleClick);
            carouselInner.addEventListener('mousedown', handleTouchStart);
            carouselInner.addEventListener('mousemove', handleTouchMove);
        }
        return function () {
            if (carouselInner) {
                carouselInner.removeEventListener('click', handleClick);
                carouselInner.removeEventListener('mousedown', handleTouchStart);
                carouselInner.removeEventListener('mousemove', handleTouchMove);
            }
        };
    }, [carouselInnerRef, handleClick, handleTouchStart, handleTouchMove]);
    React.useEffect(function () {
        var carouselInner = carouselInnerRef.current;
        if (carouselInner) {
            carouselInner.addEventListener('dragstart', handleDragStart);
        }
        return function () {
            if (carouselInner) {
                carouselInner.removeEventListener('dragstart', handleDragStart);
            }
        };
    }, [carouselInnerRef, handleDragStart]);
};
