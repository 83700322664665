import * as React from 'react';
import { IcChevron, IcPause, IcPlay } from '@assets/icons';
import { Button } from '@atomic/atm.button';
import { VisuallyHidden } from '@atomic/mol.accessibility';
import { SectionHeader } from '@atomic/mol.header/section-header.component';
import { Hbox } from '@atomic/obj.box';
import { MediaQueryContext } from '@atomic/obj.media-query';
import { CarouselPager } from './carousel-pager.component';
import { InnerCarousel } from './inner-carousel.component';
import { CarouselNextButtonStyled, CarouselPreviousButtonStyled, NewCarouselStyled, NewCarouselWrapperStyled, } from './new-carousel.component.style';
import { useAutoSlide, useThrottle } from './utils';
export var NewCarousel = function (props) {
    var _a;
    var id = props.id, title = props.title, titleHidden = props.titleHidden, loop = props.loop, _b = props.duration, duration = _b === void 0 ? 300 : _b, _c = props.minMove, minMove = _c === void 0 ? 42 : _c, auto = props.auto, _d = props.interval, interval = _d === void 0 ? 5000 : _d, children = props.children;
    var _e = React.useState(0), currentPage = _e[0], setCurrentPage = _e[1];
    var carouselRef = React.useRef();
    var carouselInnerRef = React.useRef();
    var isMediumOrLarger = React.useContext(MediaQueryContext).isMediumOrLarger;
    var announcementText = React.useRef('');
    var total = React.useMemo(function () { var _a; return (_a = React.Children.toArray(children).filter(React.isValidElement)) === null || _a === void 0 ? void 0 : _a.length; }, [children]);
    var goToNextItem = React.useCallback(function () { var _a; return (_a = carouselInnerRef.current) === null || _a === void 0 ? void 0 : _a.goToNext(); }, []);
    var _f = useAutoSlide({
        carouselRef: carouselRef,
        carouselInnerRef: (_a = carouselInnerRef.current) === null || _a === void 0 ? void 0 : _a.innerRef,
        auto: auto,
        interval: interval,
        goToNextItem: goToNextItem,
    }), paused = _f.paused, play = _f.play, pause = _f.pause;
    var handleNextItemClick = React.useCallback(function () {
        var _a;
        var nextItem = loop && currentPage === total - 1 ? 1 : currentPage + 2;
        announcementText.current = "Item ".concat(nextItem, " de ").concat(total);
        (_a = carouselInnerRef.current) === null || _a === void 0 ? void 0 : _a.goToNext();
    }, [currentPage, total, loop]);
    var handlePreviousItemClick = React.useCallback(function () {
        var _a;
        var previousItem = loop && currentPage === 0 ? total : currentPage;
        announcementText.current = "Item ".concat(previousItem, " de ").concat(total);
        (_a = carouselInnerRef.current) === null || _a === void 0 ? void 0 : _a.goToPrevious();
    }, [currentPage, total, loop]);
    var handlePagerClick = function (index) {
        var _a;
        (_a = carouselInnerRef.current) === null || _a === void 0 ? void 0 : _a.goToIndex(index);
    };
    var throttledHandleNextItemClick = useThrottle(handleNextItemClick, duration);
    var throttledHandlePreviousItemClick = useThrottle(handlePreviousItemClick, duration);
    return (React.createElement(SectionHeader, { id: id, title: title, hidden: titleHidden },
        React.createElement(NewCarouselWrapperStyled, null,
            React.createElement(NewCarouselStyled, { ref: carouselRef },
                React.createElement(InnerCarousel, { ref: carouselInnerRef, loop: loop, duration: duration, minMove: minMove, onIndexChanged: setCurrentPage }, children)),
            isMediumOrLarger && (React.createElement(React.Fragment, null,
                React.createElement(CarouselPreviousButtonStyled, null,
                    React.createElement(Button, { round: true, onClick: throttledHandlePreviousItemClick, "aria-label": 'Ir para o item anterior', disabled: !loop && currentPage === 0, variant: 'primary' },
                        React.createElement(IcChevron, { direction: 'left' }))),
                React.createElement(CarouselNextButtonStyled, null,
                    React.createElement(Button, { round: true, onClick: throttledHandleNextItemClick, "aria-label": 'Ir para o pr\u00F3ximo item', disabled: !loop && currentPage === total - 1, variant: 'primary' },
                        React.createElement(IcChevron, { direction: 'right' })))))),
        React.createElement(Hbox, null,
            !isMediumOrLarger && (React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(Button, { round: true, onClick: throttledHandlePreviousItemClick, "aria-label": 'Ir para o item anterior', disabled: !loop && currentPage === 0, variant: 'primary' },
                    React.createElement(IcChevron, { direction: 'left' })))),
            React.createElement(Hbox.Item, { hAlign: 'flex-end' },
                React.createElement(CarouselPager, { current: currentPage, total: total, onClick: handlePagerClick })),
            React.createElement(Hbox.Item, { noGrow: true }, auto && (React.createElement(Button, { round: true, onClick: paused ? play : pause, "aria-label": paused ? 'Habilitar carrossel automático' : 'Desabilitar carrossel automático', variant: 'secondary' }, paused ? React.createElement(IcPlay, null) : React.createElement(IcPause, null)))),
            !isMediumOrLarger && (React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(Button, { round: true, onClick: throttledHandleNextItemClick, "aria-label": 'Ir para o pr\u00F3ximo item', disabled: !loop && currentPage === total - 1, variant: 'primary' },
                    React.createElement(IcChevron, { direction: 'right' }))))),
        React.createElement(VisuallyHidden, null,
            React.createElement("div", { "aria-live": 'polite', "aria-atomic": true }, announcementText.current))));
};
