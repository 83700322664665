import React from 'react';
import { AppAssets } from '@app/components/atm.app-assets/app-assets';
import { H2 } from '@atomic/atm.typography';
import { VisuallyHidden } from '@atomic/mol.accessibility';
import { Separator } from '@atomic/obj.box';
import { SectionHeaderStyled } from './section-header.component.style';
export var SectionHeader = function (props) {
    var title = props.title, children = props.children, id = props.id, hidden = props.hidden, variant = props.variant;
    return (React.createElement("section", { "aria-labelledby": id },
        hidden ? (React.createElement(VisuallyHidden, null,
            React.createElement(H2, { id: id }, title))) : (React.createElement(SectionHeaderStyled, null,
            React.createElement(Separator, null),
            React.createElement(H2, { id: id, variant: variant }, title),
            React.createElement("img", { alt: '', src: AppAssets.Image.Squiggle }),
            React.createElement(Separator, null))),
        children));
};
