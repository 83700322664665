// eslint-disable-next-line import/no-unassigned-import, import/no-unresolved
import 'reflect-metadata';
import React from 'react';
// eslint-disable-next-line import/no-unassigned-import
import '@assets/fonts/fonts.css';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { configure } from '@app/configure-app';
import App from './App';
import reportWebVitals from './reportWebVitals';
configure(__serializedVariables);
var container = document.getElementById('root');
var isSSR = container.hasChildNodes();
var app = (React.createElement(React.StrictMode, null,
    React.createElement(App, null)));
if (isSSR) {
    hydrateRoot(container, app);
}
else {
    var root = createRoot(container);
    root.render(app);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
