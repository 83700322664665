var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Button } from '@atomic/atm.button';
import { BodySecondary, H3 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
export var SubSectionHeader = function (_a) {
    var Icon = _a.Icon, title = _a.title, description = _a.description, buttonProps = _a.buttonProps, buttonText = _a.buttonText;
    return (React.createElement(React.Fragment, null,
        React.createElement(Separator, null),
        React.createElement(Hbox, null,
            Icon && (React.createElement(React.Fragment, null,
                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                    React.createElement(Icon, null)),
                React.createElement(Hbox.Separator, null))),
            React.createElement(Hbox.Item, { vAlign: 'center' },
                React.createElement(H3, null, title))),
        React.createElement(Separator, { size: Spacing.Medium }),
        React.createElement(BodySecondary, null, description),
        React.createElement(Separator, null),
        !!buttonText && (React.createElement(React.Fragment, null,
            React.createElement(Button, __assign({}, buttonProps), buttonText),
            React.createElement(Separator, null)))));
};
