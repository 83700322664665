var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';
var reducedAnimationCss = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  opacity: ", ";\n  transition: ", ";\n"], ["\n  opacity: ", ";\n  transition: ", ";\n"])), function (props) { return (props.active ? 1 : 0); }, function (props) {
    return props.active ? "opacity .3s" : "opacity .3s ease-in-out, ".concat(props.from, " 0s .3s, visibility 0s .3s");
});
export var DrawerStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  ", "\n  z-index: 99;\n  background-color: ", ";\n  -webkit-overflow-scrolling: touch;\n  -ms-overflow-style: -ms-autohiding-scrollbar;\n  overscroll-behavior: contain;\n  visibility: ", ";\n  padding: ", ";\n\n  @media (prefers-reduced-motion: no-preference) {\n    transition: ", ";\n    ", "\n  }\n  @media (prefers-reduced-motion: reduce) {\n    ", "\n  }\n"], ["\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  ", "\n  z-index: 99;\n  background-color: ", ";\n  -webkit-overflow-scrolling: touch;\n  -ms-overflow-style: -ms-autohiding-scrollbar;\n  overscroll-behavior: contain;\n  visibility: ", ";\n  padding: ", ";\n\n  @media (prefers-reduced-motion: no-preference) {\n    transition: ", ";\n    ", "\n  }\n  @media (prefers-reduced-motion: reduce) {\n    ", "\n  }\n"])), function (props) { return "".concat(props.from, ": ").concat(props.active ? '0px' : "-100%", ";"); }, function (props) { var _a; return (_a = Color[props.color]) !== null && _a !== void 0 ? _a : Color.GrayXLight; }, function (props) { return (props.active ? 'visible' : 'hidden'); }, Spacing.Medium, function (props) { return (props.active ? "".concat(props.from, " .3s") : "".concat(props.from, " .3s, visibility 0s .3s")); }, function (props) { return props.theme.disableAnimations && reducedAnimationCss; }, reducedAnimationCss);
var templateObject_1, templateObject_2;
