import * as React from 'react';
import { MaxWidthContainer } from '@atomic/obj.grid';
import { MediaQueryContext } from '@atomic/obj.media-query';
import { NavMenuDesktop } from './nav-menu-desktop.component';
import { NavMenuMobile } from './nav-menu-mobile.component';
import { NavMenuStyled } from './nav-menu.component.style';
export var NavMenu = function () {
    var isLarger = React.useContext(MediaQueryContext).isLarger;
    return (React.createElement(NavMenuStyled, null,
        React.createElement(MaxWidthContainer, null, isLarger ? React.createElement(NavMenuDesktop, { items: NavMenuItems }) : React.createElement(NavMenuMobile, { items: NavMenuItems }))));
};
var NavMenuItems = [
    {
        label: 'Início',
        href: '/',
    },
    {
        label: 'Contato',
        href: '/contact',
    },
];
