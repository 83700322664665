var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { Body, H1Display } from '@atomic/atm.typography';
import { AnimatedImage } from '@atomic/mol.animated-image/animated-image.component';
import { HeroBgMdScreen } from '@atomic/mol.section/hero-bg.component';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Col, MaxWidthContainer, Row } from '@atomic/obj.grid';
export var HeroSection = function (_a) {
    var description = _a.description, buttonText = _a.buttonText, buttonProps = _a.buttonProps, Image = _a.Image, children = _a.children;
    return (React.createElement(HeroBgMdScreen, null,
        React.createElement(MaxWidthContainer, null,
            React.createElement(Row, { align: 'center' },
                React.createElement(Col, { xs: 12, md: 5 },
                    React.createElement(H1Display, null, "Somos\u00A0uma empresa\u00A0social"),
                    React.createElement(Separator, { size: Spacing.Medium }),
                    React.createElement(Body, null, description),
                    React.createElement(Separator, null),
                    React.createElement(Button, __assign({ variant: 'primary' }, buttonProps), buttonText)),
                React.createElement(Col, { xs: 4, md: 1 }),
                React.createElement(Col, { xs: 8, md: 6 },
                    React.createElement(Separator, null),
                    React.createElement(AnimatedImage, { Image: function () { return React.createElement(Image, null); } })))),
        children));
};
