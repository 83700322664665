var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { ExternalLinkStyled } from './route.style';
export var Link = function (props) {
    var isExternal = function (toHref) {
        if (typeof toHref === 'string') {
            return toHref.indexOf('://') > -1 || toHref.indexOf('//') > 0;
        }
        return false;
    };
    var to = props.to, others = __rest(props, ["to"]);
    if (isExternal(to)) {
        return (React.createElement("a", __assign({ href: to }, others),
            props.children,
            React.createElement(ExternalLinkStyled, null, " link externo")));
    }
    return React.createElement(LinkRouter, __assign({}, props));
};
