import * as React from 'react';
import { LandmarksIds } from '@atomic/mol.accessibility';
import { MaxWidthContainer } from '@atomic/obj.grid';
import { MediaQueryContext } from '@atomic/obj.media-query';
import { AccessibilityMenuDesktop } from './accessibility-menu-desktop.component';
import { AccessibilityMenuMobile } from './accessibility-menu-mobile.component';
import { AccessibilityMenuStyled } from './accessibility-menu.component.style';
export var AccessibilityMenu = function () {
    var isLarger = React.useContext(MediaQueryContext).isLarger;
    return (React.createElement(AccessibilityMenuStyled, null,
        React.createElement(MaxWidthContainer, null, isLarger ? (React.createElement(AccessibilityMenuDesktop, { items: AccessibilityMenuItems })) : (React.createElement(AccessibilityMenuMobile, { items: AccessibilityMenuItems })))));
};
var AccessibilityMenuItems = [
    {
        label: 'Ir para conteúdo',
        href: "#".concat(LandmarksIds.Main),
    },
    // {
    //   label: 'Ir para menu',
    //   href: `#${LandmarksIds.Menu.Navigation}`,
    // },
];
