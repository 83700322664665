import Thumb1 from './img/mentor-apprentice-testimony-1.png?as=webp';
import Thumb2 from './img/mentor-apprentice-testimony-2.png?as=webp';
import Thumb3 from './img/mentor-apprentice-testimony-3.png?as=webp';
export var mentorApprenticeTestimonies = [
    {
        name: 'Daniel Ueno',
        role: 'ex-aprendiz e atual mentor',
        thumb: Thumb1,
        text: 'Ao longo deste pequeno período já sinto um impacto enorme no meu desenvolvimento como programador. A visão do Instituto se alinha bastante com meus valores de generosidade mental e coerência ética. Não é trivial encontrar um ambiente onde de fato as pessoas não sejam apenas números e não apenas ensinem o que sabem, mas também pratiquem aquilo que ensinam.',
    },
    {
        name: 'Samuel Santana',
        role: 'aprendiz',
        thumb: Thumb2,
        text: 'O Instituto surgiu na minha vida em um momento muito oportuno, onde eu estava em dúvida de que área seguir e se desenvolvimento era realmente pra mim. Mas com essa oportunidade pude me desenvolver de uma forma que nem imaginava (...). Vejo o Instituto como uma oportunidade única que eu estou tendo de me desenvolver, não só em habilidades técnicas, como pessoais e que vão me agregar muito como profissional e pessoa.',
    },
    {
        name: 'Luísa Reis',
        role: 'ex-aprendiz',
        thumb: Thumb3,
        text: 'Quem me apresentou o Instituto foi o Yugo e a Paola. Eu já os conhecia por conta de uma mentoria de design que fiz em 2019. (...) O Instituto está sendo uma experiência incrível para mim e que tem me impactado muito positivamente. Diariamente, eu estudo e tenho contato com assuntos que eu não veria na faculdade, além de todo apoio e suporte que recebo aqui!',
    },
];
