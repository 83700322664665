import * as React from 'react';
import { ContactUsFormStyled } from './contact-us-form.component.style';
export var ContactUsForm = function () {
    React.useEffect(function () {
        var script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        document.body.appendChild(script);
    }, []);
    return (React.createElement(ContactUsFormStyled, { "data-tf-widget": 'iqdLwcep', "data-tf-opacity": '0', "data-tf-hide-headers": true, "data-tf-iframe-props": 'title=Agendar conversa', "data-tf-medium": 'snippet' }));
};
