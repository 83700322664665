var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Color, Shadow, Spacing } from '@atomic/obj.constants';
export var CardStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  background-color: ", ";\n  border-radius: ", ";\n\n  ", "\n"], ["\n  position: relative;\n  width: 100%;\n  background-color: ", ";\n  border-radius: ", ";\n\n  ", "\n"])), Color.White, Border.RadiusLarge, function (props) {
    return props.outlined
        ? "\n    border: ".concat(Border.Width, " solid ").concat(Color.GrayLight, ";\n  ")
        : "\n    box-shadow: ".concat(Shadow.Small, ";\n  ");
});
export var CardBodyStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", ";\n  border-radius: ", ";\n"], ["\n  padding: ", ";\n  border-radius: ", ";\n"])), function (_a) {
    var noPadding = _a.noPadding;
    return (noPadding ? 0 : Spacing.Large);
}, Border.RadiusLarge);
var templateObject_1, templateObject_2;
