import * as React from 'react';
import { Routes as LibRoutes, Route } from 'react-router-dom';
import { ContactPage } from './contact/contact.page';
import { DesignCoursePage } from './design-course/design-course.page';
import { HomePage } from './home/home.page';
import { ServicesPage } from './services/services.page';
export var Routes = function () {
    return (React.createElement(LibRoutes, null,
        React.createElement(Route, { path: '/', element: React.createElement(HomePage, null) }),
        React.createElement(Route, { path: 'contact', element: React.createElement(ContactPage, null) }),
        React.createElement(Route, { path: 'services', element: React.createElement(ServicesPage, null) }),
        React.createElement(Route, { path: 'design-course', element: React.createElement(DesignCoursePage, null) })));
};
