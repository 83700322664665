export var HomeStrings = {
    hero: {
        title: 'Somos uma empresa social',
        description: 'Impulsione seu negócio através de projetos digitais a um preço justo e ainda promova um impacto social.',
        button: 'Entre em contato',
        imageAltText: 'Pessoas mentoras, um cachorro e jovens em capacitação pelo programa do Instituto Taqtile reunidos sentados na escadaria do escritório sede com um sorriso no rosto. Fotografia. ',
    },
    how: {
        title: 'Como fazemos isso',
        companies: {
            title: 'Ajudamos pequenas e médias empresas',
            description: 'Entregamos excelentes projetos para pequenas e médias empresas, ajudando-as a crescer com um custo que cabe no orçamento.',
            seeMore: 'Conheça nossos projetos',
        },
        opportunities: {
            title: 'Dando oportunidade para quem precisa',
            description: 'Recrutamos jovens em situações de vulnerabilidade para se tornarem nossos alunos e passarem por um processo que vai da capacitação até a participação ativa nesses projetos de clientes.',
            seeMore: 'Conheça nossos alunos',
        },
        support: {
            title: 'Com o apoio de líderes sociais',
            description: 'Buscamos Líderes Sociais que capacitam os jovens e ainda garantem uma entrega de projetos com alta qualidade.',
        },
    },
    projects: {
        title: 'Conheça nossos projetos',
        seeResults: 'Confira o resultado',
        pill: {
            title: 'Pill Farmácia Digital',
            imageAltText: 'Notebook apresentando o redesign do site realizado pelas pessoas mentoradas pelo programa do Instituto Taqtile para a empresa Pill Farmácia Digital.',
            description: 'A Pill é uma startup do segmento de dark pharmacy, que opera 100% online. Seu principal diferencial é olhar para a saúde do consumidor de maneira contínua e próxima, mirando em um dos principais desafios enfrentados por doentes crônicos: a adesão medicamentosa. Por meio da compra programada de itens farmacêuticos, oferecem um atendimento personalizado realizado por farmacêuticos, com entrega grátis e preços mais atrativos que as farmácias convencionais. Fizemos o redesign do site, adequando-o à nova marca e posicionamento.',
        },
        delfos: {
            title: 'Portal de Delfos',
            imageAltText: 'Celular apresentando o resultado do trabalho realizado em pesquisa, design de telas, identidade visual e desenvolvimento do site do cliente Portal de Delfos entregue pelas pessoas mentoradas pelo programa.',
            description: "O Portal de Delfos \u00E9 uma plataforma de desenvolvimento pessoal, que permite aos usu\u00E1rios reconhecerem seus talentos e acessarem recursos para lidar com os desafios, identificados por meio de um assessment (question\u00E1rio). Tais caracter\u00EDsticas s\u00E3o trabalhadas em trilhas de conhecimento, com exerc\u00EDcios reflexivos e experienciais, compondo uma jornada de autoconhecimento que traz elementos da cultura mitol\u00F3gica grega e da psicologia junguiana.\n\n      Neste projeto, participamos de todas as pontas: da pesquisa inicial, passando pelo design das telas e identidade visual, ao desenvolvimento do c\u00F3digo.",
            link: 'https://portaldedelfos.com/',
        },
    },
    testimonies: {
        clients: 'Depoimentos dos nossos clientes',
        mentorsAndApprentices: 'Depoimentos de pessoas mentoras e aprendizes',
    },
    partners: {
        title: 'Nossos parceiros',
        corall: {
            name: 'Corall',
            link: 'https://corall.net/',
            imageAltText: 'Acessar o site da consultoria de inovação Corall Consultoria',
        },
        pence: {
            name: 'Pence',
            link: 'https://pence.ai/',
            imageAltText: 'Acessar o site da ferramenta Pence que apoia no desenvolvimento de novas lideranças',
        },
        taqtile: {
            name: 'Taqtile',
            link: 'https://taqtile.com.br/',
            imageAltText: 'Acessar o site da empresa de tecnologia Taqtile',
        },
    },
    impact: {
        title: 'Números sobre nosso impacto',
        altText: '',
        projects: {
            quantity: '+20',
            description: 'Projetos entregues',
        },
        people: {
            quantity: '31',
            description: 'Jovens impactados pelo nosso programa de capacitação',
        },
        mentorship: {
            quantity: '16',
            description: 'Mentores fixos, dentre outros profissionais que contribuem com o desenvolvimento de nossos alunos',
        },
    },
    howCanWeHelp: {
        title: 'Como podemos te ajudar a transformar seu negócio?',
        description: 'Preencha o formulário na página de contato e nos envie sua mensagem. Teremos prazer em analisar sua demanda e planejar juntos uma solução personalizada para o seu negócio',
        altText: 'Pessoa mentora e pessoa mentorada trabalhando juntas sorrindo em um projeto de produto digital utilizando um computador que se encontra à frente deles. Fotografia.',
        button: 'Entre em contato',
    },
};
