import React from 'react';
import { ToggleButtonNubStyled, ToggleButtonStyled } from '@atomic/atm.toggle-button/toggle-button.component.style';
import { BodySecondary } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
export var ToggleButton = function (props) {
    return (React.createElement(Hbox, null,
        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
            React.createElement(BodySecondary, { as: 'label', htmlFor: props.label }, props.label)),
        React.createElement(Hbox.Separator, null),
        React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
            React.createElement(ToggleButtonStyled, { id: props.label, "aria-pressed": (!!props.value).toString(), onClick: props.onToggle, "aria-label": props.label },
                React.createElement(ToggleButtonNubStyled, { pressed: props.value })))));
};
