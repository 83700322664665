import * as React from 'react';
import { AppAssets } from '@app/components/atm.app-assets/app-assets';
import { Card } from '@atomic/atm.card/card.component';
import { SecondaryHeroSection } from '@atomic/mol.section/secondary-hero-section.component';
import { Separator } from '@atomic/obj.box';
import { ContactUsForm } from './components/contact-us-form.component';
import { ContactStrings as strings } from './contact.strings';
export var ContactPage = function () {
    return (React.createElement(SecondaryHeroSection, { title: strings.title, bgImage: AppAssets.Image.BgContacts },
        React.createElement(Card, { outlined: true, noPadding: true },
            React.createElement(ContactUsForm, null)),
        React.createElement(Separator, null)));
};
