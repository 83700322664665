import React from 'react';
import { useStorage } from '@app/data/storage/use-storage.hook';
import { StorageKey } from '@app/model/storage';
export var DisableAnimationsContext = React.createContext({
    value: false,
    updateValue: function () { return null; },
});
export var DisableAnimationsProvider = function (props) {
    var storageHook = useStorage(StorageKey.DisableAnimations);
    return React.createElement(DisableAnimationsContext.Provider, { value: storageHook }, props.children);
};
