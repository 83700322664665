export var ServicesStrings = {
    title: 'Nossos serviços',
    form: {
        services: {
            label: 'Tenho interesse no orçamento e contratação de',
            options: {
                first: 'Serviço 1',
                second: 'Serviço 2',
                third: 'Serviço 3',
            },
        },
        name: {
            label: 'Nome',
            caption: 'Prazer em conhecê-lo!',
            error: {
                required: 'Campo obrigatório.',
                fullName: 'Nome precisa ser completo.',
            },
        },
        company: {
            label: 'Empresa',
        },
        site: {
            label: 'Site atual',
        },
        email: {
            label: 'E-mail',
            placeholder: 'a@a.com',
        },
        phone: {
            label: 'Celular',
        },
        description: {
            label: 'Descrição (opcional)',
            caption: 'Descreva o objetivo do projeto, com detalhes que entenda que sejam importantes considerarmos para dimensionarmos a complexidade e esforço para discutir em nossa primeira conversa.',
        },
        optin: {
            contact: 'Desejo receber informações e novidades.',
        },
        submit: 'Enviar',
    },
};
