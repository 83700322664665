var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var NewCarouselStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100vw;\n  overflow: hidden;\n  align-self: center;\n"], ["\n  width: 100vw;\n  overflow: hidden;\n  align-self: center;\n"])));
export var NewCarouselWrapperStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  position: relative;\n  flex-direction: column;\n"], ["\n  display: flex;\n  position: relative;\n  flex-direction: column;\n"])));
export var NewCarouselInnerStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  white-space: nowrap;\n  -webkit-transform-style: preserve-3d;\n  -webkit-backface-visibility: hidden;\n"], ["\n  white-space: nowrap;\n  -webkit-transform-style: preserve-3d;\n  -webkit-backface-visibility: hidden;\n"])));
export var NewCarouselItemStyled = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: inline-flex;\n  vertical-align: top;\n  justify-content: center;\n  -webkit-transform-style: preserve-3d;\n  -webkit-backface-visibility: hidden;\n"], ["\n  width: 100%;\n  display: inline-flex;\n  vertical-align: top;\n  justify-content: center;\n  -webkit-transform-style: preserve-3d;\n  -webkit-backface-visibility: hidden;\n"])));
export var CarouselPreviousButtonStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  top: 50%;\n"], ["\n  position: absolute;\n  left: 0;\n  top: 50%;\n"])));
export var CarouselNextButtonStyled = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  top: 50%;\n"], ["\n  position: absolute;\n  right: 0;\n  top: 50%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
