var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
export var SvgCss = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", "\n"], ["\n  ", ";\n  ", "\n"])), function (props) {
    return !!props.tintColor &&
        "& svg {\n      color: ".concat(props.tintColor, ";\n    }\n  ");
}, function (props) {
    return props.spin &&
        "\n    & svg {\n      animation-duration: 3000ms;\n      animation-iteration-count: infinite;\n      animation-timing-function: linear;\n      animation-name: spin;\n      \n      @keyframes spin {\n        from {\n            transform:rotate(0deg);\n        }\n        to {\n            transform:rotate(360deg);\n        }\n      }\n    }\n  ";
});
var templateObject_1;
