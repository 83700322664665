var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
export var useCarouselIndex = function (_a) {
    var loop = _a.loop, children = _a.children;
    var carouselItems = React.useMemo(function () {
        var childrenArray = React.Children.toArray(children).filter(React.isValidElement);
        if ((childrenArray === null || childrenArray === void 0 ? void 0 : childrenArray.length) > 0 && loop) {
            // add last item first and first item last for loop animation effect
            return __spreadArray(__spreadArray([childrenArray[childrenArray.length - 1]], childrenArray, true), [childrenArray[0]], false);
        }
        else {
            return childrenArray;
        }
    }, [loop, children]);
    var firstIndex = React.useMemo(function () { return (loop ? 1 : 0); }, [loop]);
    var lastIndex = React.useMemo(function () {
        return loop ? carouselItems.length - 2 : carouselItems.length - 1;
    }, [loop, carouselItems]);
    var extraLeftIndex = React.useMemo(function () { return (loop ? 0 : null); }, [loop]);
    var extraRightIndex = React.useMemo(function () { return (loop ? carouselItems.length - 1 : null); }, [loop, carouselItems]);
    var realToVisualIndexMapper = React.useCallback(function (index) {
        var _a;
        var _b;
        if (loop) {
            var indexMapper = (_a = {},
                _a[extraLeftIndex] = lastIndex,
                _a[extraRightIndex] = firstIndex,
                _a);
            return ((_b = indexMapper[index]) !== null && _b !== void 0 ? _b : index) - 1;
        }
        else {
            return index;
        }
    }, [loop, extraLeftIndex, extraRightIndex, firstIndex, lastIndex]);
    return {
        carouselItems: carouselItems,
        firstIndex: firstIndex,
        lastIndex: lastIndex,
        extraLeftIndex: extraLeftIndex,
        extraRightIndex: extraRightIndex,
        realToVisualIndexMapper: realToVisualIndexMapper,
    };
};
