import * as React from 'react';
import { SectionHeader } from '@atomic/mol.header/section-header.component';
import { MaxWidthContainer } from '@atomic/obj.grid';
import { SecondaryHeroSectionContentStyled, SecondaryHeroSectionSpaceStyled, SecondaryHeroSectionStyled, } from './secondary-hero-section.component.style';
export var SecondaryHeroSection = function (props) {
    var title = props.title, bgImage = props.bgImage;
    return (React.createElement(React.Fragment, null,
        React.createElement(SecondaryHeroSectionStyled, { bgImage: bgImage }),
        React.createElement(SecondaryHeroSectionContentStyled, null,
            React.createElement(SecondaryHeroSectionSpaceStyled, null),
            React.createElement(MaxWidthContainer, null,
                React.createElement(SectionHeader, { title: title, variant: 'light' },
                    React.createElement(SecondaryHeroSectionSpaceStyled, null),
                    props.children)))));
};
