import * as React from 'react';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { InputLabel } from '@atomic/atm.input-label/input-label.component';
import { TextField, TextAreaField } from '@atomic/atm.text-field';
import { H1 } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { ServicesStrings as strings } from './services.strings';
export var ServicesPage = function () {
    var handleSubmit = function (formData) {
        if (Object.keys(formData.error).length === 0) {
            console.log(formData);
        }
    };
    return (React.createElement(Grid, null,
        React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(H1, null, strings.title),
                React.createElement(Separator, null),
                React.createElement(Form, { onSubmit: handleSubmit },
                    React.createElement(Form.Field, { name: 'services', label: strings.form.services.label },
                        React.createElement(CheckboxField, { id: 'first' }, strings.form.services.options.first),
                        React.createElement(CheckboxField, { id: 'second' }, strings.form.services.options.second),
                        React.createElement(CheckboxField, { id: 'third' }, strings.form.services.options.third)),
                    React.createElement(Separator, null),
                    React.createElement(Form.Field, { name: 'name', label: strings.form.name.label, validationPlaceholder: strings.form.name.caption, validators: [
                            Validators.Required(strings.form.name.error.required),
                            Validators.FullNameRegex(strings.form.name.error.fullName),
                        ] },
                        React.createElement(TextField, { type: 'text' })),
                    React.createElement(Separator, null),
                    React.createElement(Form.Field, { name: 'company' },
                        React.createElement(InputLabel, { label: strings.form.company.label },
                            React.createElement(TextField, { inputLabel: true, type: 'text' }))),
                    React.createElement(Separator, null),
                    React.createElement(Form.Field, { name: 'site', label: strings.form.site.label },
                        React.createElement(TextField, { type: 'text' })),
                    React.createElement(Separator, null),
                    React.createElement(Form.Field, { name: 'email', label: strings.form.email.label },
                        React.createElement(TextField, { type: 'email', placeholder: strings.form.email.placeholder })),
                    React.createElement(Separator, null),
                    React.createElement(Form.Field, { name: 'phone', label: strings.form.phone.label },
                        React.createElement(TextField, { type: 'phone', variant: 'cel-phone' })),
                    React.createElement(Separator, null),
                    React.createElement(Form.Field, { name: 'description', label: strings.form.description.label, validationPlaceholder: strings.form.description.caption },
                        React.createElement(TextAreaField, null)),
                    React.createElement(Separator, null),
                    React.createElement(Form.Field, { name: 'optin' },
                        React.createElement(CheckboxField, { id: 'contact' }, strings.form.optin.contact)),
                    React.createElement(Separator, null),
                    React.createElement(Button, { expanded: true, type: 'submit', variant: 'primary' }, strings.form.submit))))));
};
