var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';
export var NavMenuDesktopStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n"], ["\n  flex-grow: 1;\n"])));
export var NavMenuDesktopItemListStyled = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-direction: row;\n  column-gap: ", ";\n"], ["\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-direction: row;\n  column-gap: ", ";\n"])), Spacing.Medium);
export var NavMenuDesktopItemStyled = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  list-style: none;\n  padding-left: ", ";\n  ", "\n"], ["\n  list-style: none;\n  padding-left: ", ";\n  ", "\n"])), Spacing.Medium, function (_a) {
    var current = _a.current;
    return current && "border-bottom: 4px solid ".concat(Color.Black, ";");
});
var templateObject_1, templateObject_2, templateObject_3;
