import * as React from 'react';
export var useKeyboardEscape = function (onEscape) {
    var onEscapeRef = React.useRef();
    React.useEffect(function () {
        onEscapeRef.current = onEscape;
    }, [onEscape]);
    React.useEffect(function () {
        document.addEventListener('keyup', function (e) {
            var _a;
            if (e.key === 'Escape' || e.key === 'Esc') {
                (_a = onEscapeRef.current) === null || _a === void 0 ? void 0 : _a.call(onEscapeRef);
            }
        });
    }, []);
};
