import * as React from 'react';
import { usePath } from '@app/core/route';
import { DisableAnimationsContext } from '@app/data/storage/disable-animation.context';
import { IcAccessibility } from '@assets/icons';
import { Button } from '@atomic/atm.button';
import { ToggleButton } from '@atomic/atm.toggle-button/toggle-button.component';
import { BodySecondary } from '@atomic/atm.typography';
import { LandmarksIds } from '@atomic/mol.accessibility';
import { accessibilityMenuStrings } from '@atomic/obj.accessibility-menu/accessibility-menu.strings';
import { Hbox } from '@atomic/obj.box';
import { AccessibilityMenuDesktopItemListStyled, AccessibilityMenuDesktopItemStyled, } from './accessibility-menu-desktop.component.style';
export var AccessibilityMenuDesktop = function (props) {
    var addToPath = usePath().addToPath;
    return (React.createElement("nav", { id: LandmarksIds.Menu.Accessibility, "aria-label": 'Menu de acessibilidade' },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { vAlign: 'center' },
                React.createElement(AccessibilityMenuDesktopItemListStyled, null, props.items.map(function (_a) {
                    var label = _a.label, href = _a.href;
                    return (React.createElement(AccessibilityMenuDesktopItemStyled, { key: label },
                        React.createElement(Button, { href: addToPath(href), variant: 'link', leftIcon: React.createElement(IcAccessibility, null) },
                            React.createElement(BodySecondary, null, label))));
                }))),
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(DisableAnimationsContext.Consumer, null, function (_a) {
                    var value = _a.value, updateValue = _a.updateValue;
                    return (React.createElement(ToggleButton, { label: accessibilityMenuStrings.animations, value: !value, onToggle: function () { return updateValue(!value); } }));
                })),
            React.createElement(Hbox.Separator, null))));
};
