import * as React from 'react';
import { AppAssets } from '@app/components/atm.app-assets/app-assets';
import { Card } from '@atomic/atm.card/card.component';
import { Body, H3, H4 } from '@atomic/atm.typography';
import { SecondaryHeroSection } from '@atomic/mol.section/secondary-hero-section.component';
import { Separator } from '@atomic/obj.box';
import { designCourseStrings as strings } from './design-course.strings';
export var DesignCoursePage = function () {
    return (React.createElement(SecondaryHeroSection, { title: strings.title, bgImage: AppAssets.Image.BgContacts },
        React.createElement(Card, { outlined: true },
            React.createElement(H3, null, strings.aboutInstaq.title),
            React.createElement(Separator, null),
            React.createElement(Body, null, strings.aboutInstaq.content),
            React.createElement(Separator, null),
            React.createElement(H3, null, strings.aboutCourse.title),
            React.createElement(Separator, null),
            React.createElement(Body, null, strings.aboutCourse.content),
            React.createElement(Separator, null),
            React.createElement(H3, null, strings.goals.title),
            React.createElement(Separator, null),
            React.createElement("ul", null, strings.goals.content.map(function (goal, index) { return (React.createElement("li", { key: index },
                React.createElement(Body, null, goal))); })),
            React.createElement(Separator, null),
            React.createElement(H3, null, strings.course.title),
            React.createElement(Separator, null),
            React.createElement(H4, null, strings.course.week1.title),
            React.createElement("ul", null, strings.course.week1.content.map(function (content, index) { return (React.createElement("li", { key: index },
                React.createElement(Body, null, content))); })),
            React.createElement(Separator, null),
            React.createElement(H4, null, strings.course.week2.title),
            React.createElement("ul", null, strings.course.week2.content.map(function (content, index) { return (React.createElement("li", { key: index },
                React.createElement(Body, null, content))); })),
            React.createElement(Separator, null),
            React.createElement(H4, null, strings.course.week3.title),
            React.createElement("ul", null, strings.course.week3.content.map(function (content, index) { return (React.createElement("li", { key: index },
                React.createElement(Body, null, content))); })),
            React.createElement(Separator, null),
            React.createElement(H4, null, strings.course.week4.title),
            React.createElement("ul", null, strings.course.week4.content.map(function (content, index) { return (React.createElement("li", { key: index },
                React.createElement(Body, null, content))); })),
            React.createElement(Separator, null),
            React.createElement(H4, null, strings.course.week5.title),
            React.createElement("ul", null, strings.course.week5.content.map(function (content, index) { return (React.createElement("li", { key: index },
                React.createElement(Body, null, content))); })),
            React.createElement(Separator, null),
            React.createElement(H4, null, strings.course.week6.title),
            React.createElement(Separator, null),
            React.createElement(Body, null, strings.course.week6.content),
            React.createElement(Separator, null),
            React.createElement(H3, null, strings.aditionalResources.title),
            React.createElement(Separator, null),
            React.createElement(H4, null, strings.aditionalResources.books.title),
            React.createElement("ul", null, strings.aditionalResources.books.content.map(function (content, index) { return (React.createElement("li", { key: index },
                React.createElement(Body, null, content))); })),
            React.createElement(Separator, null),
            React.createElement(H4, null, strings.aditionalResources.sites.title),
            React.createElement("ul", null, strings.aditionalResources.sites.content.map(function (content, index) { return (React.createElement("li", { key: index },
                React.createElement(Body, null, content))); }))),
        React.createElement(Separator, null)));
};
