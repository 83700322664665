import * as React from 'react';
//todo: import { hasWindow } from '@app/core/browser/platform';
var hasWindow = function () { return typeof window !== 'undefined'; };
export var useCarouselSwipe = function (params) {
    var activeIndex = params.activeIndex, carouselInnerRef = params.carouselInnerRef, minMove = params.minMove, loop = params.loop, firstIndex = params.firstIndex, lastIndex = params.lastIndex, showItems = params.showItems, goToIndex = params.goToIndex;
    var isTouchingDown = React.useRef(false);
    var startX = React.useRef(null);
    var deltaX = React.useRef(null);
    var handleTouchDown = React.useCallback(function (e) {
        var _a;
        isTouchingDown.current = true;
        var pageX = (((_a = e.touches) === null || _a === void 0 ? void 0 : _a[0]) || e).pageX;
        startX.current = pageX;
        deltaX.current = 0;
    }, []);
    var handleTouchMove = React.useCallback(function (e) {
        var _a, _b;
        if (((_a = e.touches) === null || _a === void 0 ? void 0 : _a.length) > 1 || !isTouchingDown.current) {
            return;
        }
        var pageX = (((_b = e.touches) === null || _b === void 0 ? void 0 : _b[0]) || e).pageX;
        deltaX.current = pageX - startX.current;
        // when reach frames edge in non-loop mode, reduce drag effect.
        if (!loop) {
            if (activeIndex === lastIndex) {
                if (deltaX.current < 0) {
                    deltaX.current /= 3;
                }
            }
            if (activeIndex === firstIndex) {
                if (deltaX.current > 0) {
                    deltaX.current /= 3;
                }
            }
        }
        if (!carouselInnerRef.current) {
            return;
        }
        showItems();
        carouselInnerRef.current.style.transitionDuration = '0ms';
        var translateX = "calc(-".concat(activeIndex * 100, "% + ").concat(deltaX.current, "px)");
        carouselInnerRef.current.style.transform = "translateX(".concat(translateX, ")");
    }, [carouselInnerRef, activeIndex, firstIndex, lastIndex, loop, showItems]);
    var handleTouchEnd = React.useCallback(function () {
        if (deltaX.current > minMove) {
            goToIndex(activeIndex - 1);
        }
        else if (deltaX.current < -minMove) {
            goToIndex(activeIndex + 1);
        }
        else if (deltaX.current) {
            goToIndex(activeIndex);
        }
        startX.current = null;
        deltaX.current = null;
        isTouchingDown.current = false;
    }, [activeIndex, minMove, goToIndex]);
    React.useEffect(function () {
        var innerRef = carouselInnerRef.current;
        if (innerRef) {
            innerRef.addEventListener('touchstart', handleTouchDown);
            innerRef.addEventListener('mousedown', handleTouchDown);
        }
        return function () {
            if (innerRef) {
                innerRef.removeEventListener('touchstart', handleTouchDown);
                innerRef.removeEventListener('mousedown', handleTouchDown);
            }
        };
    }, [carouselInnerRef, handleTouchDown]);
    React.useEffect(function () {
        var innerRef = carouselInnerRef.current;
        if (innerRef) {
            innerRef.addEventListener('touchmove', handleTouchMove);
            innerRef.addEventListener('mousemove', handleTouchMove);
        }
        return function () {
            if (innerRef) {
                innerRef.removeEventListener('touchmove', handleTouchMove);
                innerRef.removeEventListener('mousemove', handleTouchMove);
            }
        };
    }, [carouselInnerRef, handleTouchMove]);
    React.useEffect(function () {
        if (hasWindow()) {
            window.addEventListener('mouseup', handleTouchEnd, false);
            window.addEventListener('touchend', handleTouchEnd, false);
        }
        return function () {
            if (hasWindow()) {
                window.removeEventListener('mouseup', handleTouchEnd, false);
                window.removeEventListener('touchend', handleTouchEnd, false);
            }
        };
    }, [handleTouchEnd]);
};
