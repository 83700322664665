var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { media, Spacing } from '@atomic/obj.constants';
export var SplitSectionWrapperStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n\n  gap: ", ";\n  @media only screen and ", " {\n    flex-direction: column;\n  }\n  @media only screen and ", " {\n    flex-direction: row;\n  }\n"], ["\n  display: flex;\n\n  gap: ", ";\n  @media only screen and ", " {\n    flex-direction: column;\n  }\n  @media only screen and ", " {\n    flex-direction: row;\n  }\n"])), Spacing.Large, media.min, media.medium);
export var SplitSectionHalfStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
export var SplitSectionStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: 100%;\n"])));
export var SplitSectionHalfImageWrapperStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  gap: ", ";\n\n  ", "\n\n  ", "\n\n    ", "\n\n  flex: 1;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  gap: ", ";\n\n  ", "\n\n  ", "\n\n    ", "\n\n  flex: 1;\n"])), Spacing.Large, function (props) {
    return props.hideOnSmallScreen &&
        "\n    display: none;\n  ";
}, function (props) {
    return props.mediumScreenJustifyContent && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      @media only screen and ", " {\n        display: flex;\n        justify-content: ", ";\n      }\n    "], ["\n      @media only screen and ", " {\n        display: flex;\n        justify-content: ", ";\n      }\n    "])), media.medium, props.mediumScreenJustifyContent);
}, function (props) {
    return props.hideOnMediumScreen &&
        "@media only screen and ".concat(media.medium, " {\n        display: none;\n      }");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
