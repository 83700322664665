import * as React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
export var Route = function (props) {
    return React.createElement(BrowserRouter, null, props.children);
};
export var ScrollToTop = function () {
    var pathname = useLocation().pathname;
    React.useEffect(function () {
        window.scrollTo(0, 0);
    }, [pathname]);
    return React.createElement(React.Fragment, null);
};
