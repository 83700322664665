var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Spacing, media } from '@atomic/obj.constants';
var HboxStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-content: flex-start;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-content: flex-start;\n"])));
export var Hbox = HboxStyled;
Hbox.displayName = 'Hbox';
Hbox.Item = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-direction: column;\n  display: flex;\n  ", "\n  justify-content: ", ";\n  align-items: ", ";\n"], ["\n  flex-direction: column;\n  display: flex;\n  ", "\n  justify-content: ", ";\n  align-items: ", ";\n"])), function (props) { return (!props.noGrow ? 'flex: 1;' : null); }, function (props) { return (props.vAlign ? props.vAlign : 'flex-start'); }, function (props) { return (props.hAlign ? props.hAlign : 'stretch'); });
Hbox.Item.displayName = 'Hbox.Item';
Hbox.Separator = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), function (props) { var _a; return (_a = props.size) !== null && _a !== void 0 ? _a : Spacing.Small; });
Hbox.Separator.displayName = 'Hbox.Separator';
export var Separator = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), function (props) { var _a; return (_a = props.size) !== null && _a !== void 0 ? _a : Spacing.Large; });
export var SectionSeparator = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 60px;\n\n  @media only screen and ", " {\n    height: 120px;\n  }\n"], ["\n  height: 60px;\n\n  @media only screen and ", " {\n    height: 120px;\n  }\n"])), media.medium);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
