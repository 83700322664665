import * as React from 'react';
import { usePrefersReducedMotion } from '@atomic/mol.accessibility';
var hasWindow = function () { return typeof window !== 'undefined'; };
export var useAutoSlide = function (_a) {
    var carouselRef = _a.carouselRef, carouselInnerRef = _a.carouselInnerRef, auto = _a.auto, interval = _a.interval, goToNextItem = _a.goToNextItem;
    var _b = React.useState(), paused = _b[0], setPaused = _b[1];
    var hasFocus = React.useRef(false);
    var hasHover = React.useRef(false);
    var autoSlideTimeoutRef = React.useRef();
    var prefersReducedMotion = usePrefersReducedMotion().prefersReducedMotion;
    React.useEffect(function () {
        if (paused === undefined && prefersReducedMotion !== undefined) {
            setPaused(prefersReducedMotion);
        }
    }, [paused, prefersReducedMotion]);
    var playIfPossible = React.useCallback(function () {
        if (auto && !paused && !hasFocus.current && !hasHover.current) {
            clearTimeout(autoSlideTimeoutRef.current);
            autoSlideTimeoutRef.current = setTimeout(function () {
                goToNextItem();
            }, interval);
        }
    }, [auto, interval, paused, goToNextItem]);
    var pause = React.useCallback(function () {
        clearTimeout(autoSlideTimeoutRef.current);
    }, []);
    var manualPlay = React.useCallback(function () {
        setPaused(false);
        playIfPossible();
    }, [playIfPossible]);
    var manualPause = React.useCallback(function () {
        setPaused(true);
        clearTimeout(autoSlideTimeoutRef.current);
    }, []);
    React.useEffect(function () {
        playIfPossible();
        return function () { return clearTimeout(autoSlideTimeoutRef.current); };
    }, [playIfPossible]);
    React.useEffect(function () {
        var carousel = carouselRef.current;
        var handleFocusIn = function () {
            hasFocus.current = true;
            clearTimeout(autoSlideTimeoutRef.current);
        };
        var handleFocusOut = function () {
            hasFocus.current = false;
            playIfPossible();
        };
        carousel.addEventListener('focusin', handleFocusIn);
        carousel.addEventListener('focusout', handleFocusOut);
        return function () {
            if (carousel) {
                carousel.removeEventListener('focusin', handleFocusIn);
                carousel.removeEventListener('focusout', handleFocusOut);
            }
        };
    }, [carouselRef, playIfPossible]);
    React.useEffect(function () {
        var carousel = carouselRef.current;
        var handleHoverIn = function () {
            hasHover.current = true;
            clearTimeout(autoSlideTimeoutRef.current);
        };
        var handleHoverOut = function () {
            hasHover.current = false;
            playIfPossible();
        };
        carousel.addEventListener('mouseenter', handleHoverIn);
        carousel.addEventListener('mouseleave', handleHoverOut);
        return function () {
            if (carousel) {
                carousel.removeEventListener('mouseenter', handleHoverIn);
                carousel.removeEventListener('mouseleave', handleHoverOut);
            }
        };
    }, [carouselRef, playIfPossible]);
    React.useEffect(function () {
        var carousel = carouselRef.current;
        if (carousel) {
            // touch
            carousel.addEventListener('touchstart', pause);
            carousel.addEventListener('mousedown', pause);
        }
        if (hasWindow()) {
            // release touch
            window.addEventListener('mouseup', playIfPossible);
            window.addEventListener('touchend', playIfPossible);
        }
        return function () {
            if (carousel) {
                carousel.removeEventListener('touchstart', pause);
                carousel.removeEventListener('mousedown', pause);
            }
            if (hasWindow()) {
                window.removeEventListener('mouseup', playIfPossible);
                window.removeEventListener('touchend', playIfPossible);
            }
        };
    }, [carouselRef, playIfPossible, pause]);
    React.useEffect(function () {
        var carouselInner = carouselInnerRef === null || carouselInnerRef === void 0 ? void 0 : carouselInnerRef.current;
        if (carouselInner) {
            // restart auto slide after transition
            carouselInner.addEventListener('transitionend', playIfPossible);
        }
        return function () {
            if (carouselInner) {
                carouselInner.removeEventListener('transitionend', playIfPossible);
            }
        };
    }, [carouselInnerRef, playIfPossible]);
    return { paused: paused, play: manualPlay, pause: manualPause };
};
