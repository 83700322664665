var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { media } from '@atomic/obj.constants';
var bgDelayTime = '0.2s';
var invertedAnimationSuffix = 'Inverted';
export var ResponsiveImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  ", "\n  width: 100%;\n  height: auto;\n  max-height: 65vh;\n  object-fit: contain;\n\n  @media only screen and ", " {\n    max-width: 45vw;\n  }\n"], ["\n  ", "\n  ", "\n  ", "\n  width: 100%;\n  height: auto;\n  max-height: 65vh;\n  object-fit: contain;\n\n  @media only screen and ", " {\n    max-width: 45vw;\n  }\n"])), function (props) { return props.visibilityHidden && 'visibility: hidden;'; }, function (props) { return props.verticalAlign && "vertical-align: ".concat(props.verticalAlign, ";"); }, function (props) { return props.objectPosition && "object-position: ".concat(props.objectPosition, ";"); }, media.large);
export var disableAnimationClassName = 'withoutAnimation';
var disableAnimationStyle = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  opacity: 1;\n  animation: none;\n"], ["\n  opacity: 1;\n  animation: none;\n"])));
var disabledAnimationClassStyle = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &.", " {\n    ", "\n  }\n"], ["\n  &.", " {\n    ", "\n  }\n"])), disableAnimationClassName, disableAnimationStyle);
export var animatedImageBackgroundClassName = 'withBackgroundAnimation';
var imageBackgroundAnimationClassStyle = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &.", " {\n    animation: wait ", ",\n      ease-in-out 0.6s ", "\n        ", ";\n\n    animation-fill-mode: forwards;\n\n    @keyframes wait {\n      0% {\n        opacity: 0;\n      }\n      100% {\n        opacity: 0;\n      }\n    }\n\n    @keyframes ", " {\n      0% {\n        opacity: 0;\n        transform: ", ";\n      }\n      100% {\n        transform: translateX(0) translateY(0);\n        opacity: 1;\n      }\n    }\n  }\n"], ["\n  &.", " {\n    animation: wait ", ",\n      ease-in-out 0.6s ", "\n        ", ";\n\n    animation-fill-mode: forwards;\n\n    @keyframes wait {\n      0% {\n        opacity: 0;\n      }\n      100% {\n        opacity: 0;\n      }\n    }\n\n    @keyframes ", " {\n      0% {\n        opacity: 0;\n        transform: ", ";\n      }\n      100% {\n        transform: translateX(0) translateY(0);\n        opacity: 1;\n      }\n    }\n  }\n"])), animatedImageBackgroundClassName, bgDelayTime, bgDelayTime, function (props) { return 'slideAnimation' + (props.invertAnimation ? invertedAnimationSuffix : ''); }, function (props) { return 'slideAnimation' + (props.invertAnimation ? invertedAnimationSuffix : ''); }, function (props) {
    return props.invertAnimation ? "translateX(-100%) translateY(10%)" : "translateX(100%) translateY(10%)";
});
export var AnimatedImageBackgroundStyled = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  z-index: -1;\n  position: absolute;\n\n  opacity: 0;\n\n  ", "\n\n  ", "\n\n  @media ", " {\n    &.", ", &.", " {\n      ", "\n    }\n  }\n\n  ", "\n"], ["\n  z-index: -1;\n  position: absolute;\n\n  opacity: 0;\n\n  ", "\n\n  ", "\n\n  @media ", " {\n    &.", ", &.", " {\n      ", "\n    }\n  }\n\n  ", "\n"])), function (props) {
    return props.negativeMargin && props.invertAnimation
        ? "left: -".concat(props.negativeMargin, ";")
        : "right: -".concat(props.negativeMargin, ";");
}, imageBackgroundAnimationClassStyle, media.prefersReducedMotion, disableAnimationClassName, animatedImageBackgroundClassName, disableAnimationStyle, disabledAnimationClassStyle);
export var animatedImageClassName = 'withImageAnimation';
var animatedImageClassStyle = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  &.", " {\n    opacity: 1;\n    transform: scaleX(1) scaleY(1);\n\n    animation-name: scaleAnimation;\n    animation-iteration-count: 1;\n    animation-timing-function: ease-in-out;\n    animation-duration: 0.6s;\n\n    animation-fill-mode: forwards;\n\n    @keyframes scaleAnimation {\n      0% {\n        transform: scaleX(0) scaleY(0);\n        opacity: 0;\n      }\n      100% {\n        transform: scaleX(1) scaleY(1);\n        opacity: 1;\n      }\n    }\n  }\n"], ["\n  &.", " {\n    opacity: 1;\n    transform: scaleX(1) scaleY(1);\n\n    animation-name: scaleAnimation;\n    animation-iteration-count: 1;\n    animation-timing-function: ease-in-out;\n    animation-duration: 0.6s;\n\n    animation-fill-mode: forwards;\n\n    @keyframes scaleAnimation {\n      0% {\n        transform: scaleX(0) scaleY(0);\n        opacity: 0;\n      }\n      100% {\n        transform: scaleX(1) scaleY(1);\n        opacity: 1;\n      }\n    }\n  }\n"])), animatedImageClassName);
export var AnimatedImageStyled = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n\n  opacity: 0;\n\n  ", "\n\n  @media ", " {\n    &.", ", &.", " {\n      ", "\n    }\n  }\n\n  ", "\n"], ["\n  width: 100%;\n\n  opacity: 0;\n\n  ", "\n\n  @media ", " {\n    &.", ", &.", " {\n      ", "\n    }\n  }\n\n  ", "\n"])), animatedImageClassStyle, media.prefersReducedMotion, disableAnimationClassName, animatedImageClassName, disableAnimationStyle, disabledAnimationClassStyle);
export var AnimationWrapperStyled = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  witdh: 100%;\n"], ["\n  witdh: 100%;\n"])));
export var AnimationReferenceDivStyled = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  display: inline-flex;\n  width: 100%;\n"], ["\n  position: relative;\n  display: inline-flex;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
