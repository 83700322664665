import * as React from 'react';
import FocusTrap from 'focus-trap-react';
import { LandmarksIds } from '@atomic/mol.accessibility';
import { DrawerStyled } from './drawer.component.style';
export var Drawer = function (props) {
    var active = props.active;
    React.useEffect(function () {
        var main = document.getElementById(LandmarksIds.Main);
        var footer = document.getElementById(LandmarksIds.Footer);
        [main, footer].forEach(function (element) {
            if (element) {
                // for mobile screen reader
                element.ariaHidden = active ? 'true' : 'false';
            }
        });
    }, [active]);
    return (React.createElement(FocusTrap, { active: active, focusTrapOptions: { returnFocusOnDeactivate: false } },
        React.createElement(DrawerStyled, { active: active, from: props.from, color: props.color }, props.children)));
};
