var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Container, Col as FlexCol, Row as FlexRow } from 'react-grid-system';
import styled from 'styled-components';
import { Breakpoint, MediaQuery, Spacing } from '@atomic/obj.constants';
var RowStyled = styled(FlexRow)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: ", ";\n  margin-top: ", ";\n  ", "\n"], ["\n  margin-bottom: ", ";\n  margin-top: ", ";\n  ", "\n"])), function (props) { return (props.mb ? Spacing.Medium : '0px'); }, function (props) { return (props.mt ? Spacing.Medium : '0px'); }, function (props) {
    if (props.noGutter) {
        return "overflow: hidden;\n              margin-left: -".concat(rowMargin, "rem;\n              margin-right: -").concat(rowMargin, "rem;");
    }
    else {
        return '';
    }
});
export var Grid = Container;
export var Col = FlexCol;
export var Row = RowStyled;
export var GridSettings = {
    gridColumns: 12,
    // Defaults
    gutterWidth: 30,
    breakpoints: [Breakpoint.sm, Breakpoint.md, Breakpoint.lg, Breakpoint.xl, Breakpoint.xxl],
};
export var rowMargin = GridSettings.gutterWidth / 2;
export var MaxWidthContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 ", ";\n  ", " {\n    padding: 0 ", ";\n  }\n  ", " {\n    padding: 0 80px;\n  }\n  max-width: 1280px;\n  width: 100%;\n  margin: 0 auto;\n"], ["\n  padding: 0 ", ";\n  ", " {\n    padding: 0 ", ";\n  }\n  ", " {\n    padding: 0 80px;\n  }\n  max-width: 1280px;\n  width: 100%;\n  margin: 0 auto;\n"])), Spacing.Medium, MediaQuery.md, Spacing.XLarge, MediaQuery.xl);
var templateObject_1, templateObject_2;
