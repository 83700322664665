import * as React from 'react';
import { AppAssets } from '@app/components/atm.app-assets/app-assets';
import { AppIcon } from '@app/components/atm.app-icon';
import { TestimonyCarousel } from '@app/components/mol.testimony-carousel';
import { clientTestimonies } from '@app/data/local';
import { mentorApprenticeTestimonies } from '@app/data/local/mentor-apprentice-testimonies';
import { ArchBg, EllipseBg, ImpactBg, ImpactFront, SpaceBg } from '@atomic/atm.background/background.styled';
import { AnimatedImage } from '@atomic/mol.animated-image/animated-image.component';
import { ResponsiveImage } from '@atomic/mol.animated-image/animated-image.component.style';
import { VerticalDefinitionListCard } from '@atomic/mol.definition-list-card/definition-list-card.component';
import { SectionHeader } from '@atomic/mol.header/section-header.component';
import { SubSectionHeader } from '@atomic/mol.header/sub-section-header.component';
import { ImageGrid } from '@atomic/mol.image-grid/image-grid.component';
import { HeroSection } from '@atomic/mol.section/hero-section.component';
import { LastSection } from '@atomic/mol.section/last-section.component';
import { SplitSection } from '@atomic/mol.section/split-section.component';
import { SectionSeparator, Separator } from '@atomic/obj.box';
import { Col, MaxWidthContainer, Row } from '@atomic/obj.grid';
import { HomeStrings as strings } from './home.strings';
var ourProjectsId = 'nossos-projetos';
var clientsId = 'nossos-clientes';
var mentorAndApprenticeId = 'nossos-mentores-e-aprendizes';
export var HomePage = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(HeroSection, { description: strings.hero.description, buttonText: strings.hero.button, buttonProps: { href: '/contact' }, Image: function () { return React.createElement(ResponsiveImage, { alt: strings.hero.imageAltText, src: AppAssets.Image.Hero }); } },
            React.createElement(SectionSeparator, null),
            React.createElement(MaxWidthContainer, null,
                React.createElement(SectionHeader, { title: strings.how.title },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(SubSectionHeader, { title: strings.how.companies.title, description: strings.how.companies.description, Icon: AppIcon.Briefcase })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(SubSectionHeader, { title: strings.how.opportunities.title, description: strings.how.opportunities.description, Icon: AppIcon.Handshake })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(SubSectionHeader, { title: strings.how.companies.title, description: strings.how.support.description, Icon: AppIcon.Group })))),
                React.createElement(SectionSeparator, null))),
        React.createElement(ArchBg, { bgColor: 'primary' },
            React.createElement(MaxWidthContainer, null,
                React.createElement(SectionHeader, { title: strings.projects.title, id: ourProjectsId },
                    React.createElement(SplitSection, { contentFirst: true, Image: function () { return (React.createElement(AnimatedImage, { Image: function () { return (React.createElement(ResponsiveImage, { alt: strings.projects.pill.imageAltText, src: AppAssets.Image.Computer })); }, Background: function () { return React.createElement(ResponsiveImage, { alt: '', src: AppAssets.Image.BgBlue }); } })); }, Content: function () { return (React.createElement(SubSectionHeader, { title: strings.projects.pill.title, description: strings.projects.pill.description })); } }),
                    React.createElement(Separator, null),
                    React.createElement(SplitSection, { Image: function () { return (React.createElement(AnimatedImage, { invertAnimation: true, Image: function () { return (React.createElement(ResponsiveImage, { alt: strings.projects.delfos.imageAltText, src: AppAssets.Image.Phone })); }, Background: function () { return React.createElement(ResponsiveImage, { alt: '', src: AppAssets.Image.BgGreen }); } })); }, Content: function () { return (React.createElement(SubSectionHeader, { title: strings.projects.delfos.title, description: strings.projects.delfos.description, buttonText: strings.projects.seeResults, buttonProps: {
                                href: strings.projects.delfos.link,
                                variant: 'link',
                            } })); } })),
                React.createElement(SectionSeparator, null))),
        React.createElement(SpaceBg, null,
            React.createElement(MaxWidthContainer, null,
                React.createElement(TestimonyCarousel, { id: clientsId, title: strings.testimonies.clients, testimonies: clientTestimonies }),
                React.createElement(SectionSeparator, null)),
            React.createElement(ArchBg, { bgColor: 'white' },
                React.createElement(MaxWidthContainer, null,
                    React.createElement(SectionHeader, { title: strings.partners.title },
                        React.createElement(ImageGrid, { items: [
                                {
                                    source: AppAssets.Image.PartnerCorall,
                                    alt: strings.partners.corall.imageAltText,
                                    href: strings.partners.corall.link,
                                },
                                {
                                    source: AppAssets.Image.PartnerPence,
                                    alt: strings.partners.pence.imageAltText,
                                    href: strings.partners.pence.link,
                                },
                                {
                                    source: AppAssets.Image.PartnerTaqtile,
                                    alt: strings.partners.taqtile.imageAltText,
                                    href: strings.partners.taqtile.link,
                                },
                            ] })),
                    React.createElement(SectionSeparator, null),
                    React.createElement(SectionHeader, { title: strings.impact.title },
                        React.createElement(Row, { align: 'center' },
                            React.createElement(Col, { xs: 12, md: 6 },
                                React.createElement(ImpactFront, null,
                                    React.createElement(AnimatedImage, { Image: function () { return React.createElement(ResponsiveImage, { alt: strings.impact.altText, src: AppAssets.Image.Impact }); }, invertAnimation: true, Background: function () { return (React.createElement(ImpactBg, null,
                                            React.createElement(ResponsiveImage, { alt: '', src: AppAssets.Image.BgBlueAlternate }))); } }))),
                            React.createElement(Col, { xs: 12, md: 6 },
                                React.createElement(VerticalDefinitionListCard, { items: [
                                        {
                                            title: strings.impact.projects.quantity,
                                            description: strings.impact.projects.description,
                                        },
                                        {
                                            title: strings.impact.people.quantity,
                                            description: strings.impact.people.description,
                                        },
                                        {
                                            title: strings.impact.mentorship.quantity,
                                            description: strings.impact.mentorship.description,
                                        },
                                    ] })))),
                    React.createElement(SectionSeparator, null)))),
        React.createElement(EllipseBg, null,
            React.createElement(MaxWidthContainer, null,
                React.createElement(TestimonyCarousel, { id: mentorAndApprenticeId, title: strings.testimonies.mentorsAndApprentices, testimonies: mentorApprenticeTestimonies }),
                React.createElement(SectionSeparator, null)),
            React.createElement(LastSection, { href: '/contact', title: strings.howCanWeHelp.title, description: strings.howCanWeHelp.description, button: strings.howCanWeHelp.button, Image: function () { return (React.createElement(ResponsiveImage, { alt: strings.howCanWeHelp.altText, src: AppAssets.Image.HowCanWeHelpBottomRight, verticalAlign: 'bottom', objectPosition: 'right' })); }, Background: function () { return React.createElement(ResponsiveImage, { alt: '', src: AppAssets.Image.BgCirclesBottomRight }); } }))));
};
