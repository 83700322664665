import * as React from 'react';
import { usePrefersReducedMotion } from '@atomic/mol.accessibility';
export var useCarouselAnimation = function (params) {
    var carouselInnerRef = params.carouselInnerRef, duration = params.duration, showItems = params.showItems;
    var prefersReducedMotion = usePrefersReducedMotion().prefersReducedMotion;
    var swipeToIndex = React.useCallback(function (index) {
        var _a;
        if ((_a = carouselInnerRef.current) === null || _a === void 0 ? void 0 : _a.style) {
            // set duration to 1 not 0 so onTransitionEnd is still called
            carouselInnerRef.current.style.transitionDuration = (prefersReducedMotion ? 1 : duration) + 'ms';
            carouselInnerRef.current.style.transform = "translateX(calc(-".concat(index * 100, "%)");
        }
        showItems();
    }, [carouselInnerRef, duration, prefersReducedMotion, showItems]);
    var sneakToIndex = React.useCallback(function (index) {
        var _a;
        if ((_a = carouselInnerRef.current) === null || _a === void 0 ? void 0 : _a.style) {
            carouselInnerRef.current.style.transitionDuration = '0ms';
            carouselInnerRef.current.style.transform = "translateX(calc(-".concat(index * 100, "%)");
        }
    }, [carouselInnerRef]);
    return { swipeToIndex: swipeToIndex, sneakToIndex: sneakToIndex };
};
