import BgBlue from '@assets/img/img_bg_blue.svg';
import BgBlueAlternate from '@assets/img/img_bg_blue_alternate.svg';
import BgCirclesBottomRight from '@assets/img/img_bg_circles_bottom_right.png?as=webp';
import BgContacts from '@assets/img/img_bg_contacts.png?as=webp';
import BgEllipse from '@assets/img/img_bg_ellipse.svg';
import BgGreen from '@assets/img/img_bg_green.svg';
import BgSpace from '@assets/img/img_bg_space.svg';
import Computer from '@assets/img/img_computer.png?as=webp';
import BgHalfGreenCircle from '@assets/img/img_half_green_circle_bg.svg';
import Hero from '@assets/img/img_hero.png?as=webp';
import HowCanWeHelpBottomRight from '@assets/img/img_how_can_we_help_bottom_right.png?as=webp';
import Impact from '@assets/img/img_impact.png?as=webp';
import BgMultiColorCircles from '@assets/img/img_multi_color_circles_bg.svg';
import PartnerCorall from '@assets/img/img_partner_corall.svg';
import PartnerPence from '@assets/img/img_partner_pence.svg';
import PartnerTaqtile from '@assets/img/img_partner_taqtile.svg';
import Phone from '@assets/img/img_phone.png?as=webp';
import QuotationMark from '@assets/img/img_quotation_mark.png?as=webp';
import Squiggle from '@assets/img/img_squiggle.svg';
export var AppAssets = {
    Image: {
        BgHalfGreenCircle: BgHalfGreenCircle,
        BgMultiColorCircles: BgMultiColorCircles,
        BgBlue: BgBlue,
        BgBlueAlternate: BgBlueAlternate,
        BgCirclesBottomRight: BgCirclesBottomRight,
        BgContacts: BgContacts,
        BgEllipse: BgEllipse,
        BgGreen: BgGreen,
        BgSpace: BgSpace,
        Computer: Computer,
        Hero: Hero,
        HowCanWeHelpBottomRight: HowCanWeHelpBottomRight,
        Impact: Impact,
        Phone: Phone,
        QuotationMark: QuotationMark,
        Squiggle: Squiggle,
        PartnerCorall: PartnerCorall,
        PartnerTaqtile: PartnerTaqtile,
        PartnerPence: PartnerPence,
    },
};
