export var BrandColor;
(function (BrandColor) {
    BrandColor["Blue"] = "#16AAE4";
    BrandColor["Pink"] = "#FF388B";
    BrandColor["Purple"] = "#663AE7";
    BrandColor["PrimaryXLight"] = "#CDFEF7";
    BrandColor["PrimaryLight"] = "#06C6AC";
    BrandColor["PrimaryMedium"] = "#036356";
    BrandColor["PrimaryDark"] = "#224E48";
    BrandColor["PrimaryXDark"] = "#01322B";
})(BrandColor || (BrandColor = {}));
var FeedbackColor = {
    Success: {
        Light: '#DCF6ED',
        Medium: '#4FD3A5',
        Dark: '#2F7F63',
    },
    Warning: {
        Light: '#FEEDB4',
        Medium: '#FCD343',
        Dark: '#65541B',
    },
    Alert: {
        Light: '#FADBDF',
        Medium: '#E54D5D',
        Dark: '#892E38',
    },
};
// We used class rather than enums since enums don't support computed values
var Color = /** @class */ (function () {
    function Color() {
    }
    Color.Black = '#222222';
    Color.White = 'white';
    Color.GrayXLight = '#F2F4F4';
    Color.GrayLight = '#E5E9EA';
    Color.Gray = '#99A6AB';
    Color.GrayDark = '#646C6F';
    Color.GrayXDark = '#2E3233';
    Color.PrimaryXLight = BrandColor.PrimaryXLight;
    Color.PrimaryLight = BrandColor.PrimaryLight;
    Color.Primary = BrandColor.PrimaryMedium;
    Color.PrimaryDark = BrandColor.PrimaryDark;
    Color.PrimaryXDark = BrandColor.PrimaryXDark;
    Color.Secondary = BrandColor.Blue;
    Color.Accessory = BrandColor.Purple;
    Color.Neutral = Color.GrayDark;
    Color.CallToAction = BrandColor.Pink;
    Color.Alert = FeedbackColor.Alert;
    Color.Warning = FeedbackColor.Warning;
    Color.Success = FeedbackColor.Success;
    return Color;
}());
export { Color };
export var FontFamily;
(function (FontFamily) {
    FontFamily["Primary"] = "Work Sans";
})(FontFamily || (FontFamily = {}));
export var FontWeight;
(function (FontWeight) {
    FontWeight[FontWeight["Medium"] = 500] = "Medium";
    FontWeight["Bold"] = "bold";
    FontWeight["Regular"] = "normal";
    FontWeight[FontWeight["Black"] = 700] = "Black";
})(FontWeight || (FontWeight = {}));
export var FontSize;
(function (FontSize) {
    FontSize["XSmall"] = "10px";
    FontSize["Small"] = "14px";
    FontSize["Medium"] = "16px";
    FontSize["Large"] = "20px";
    FontSize["XLarge"] = "24px";
    FontSize["XXLarge"] = "32px";
    FontSize["XXXLarge"] = "40px";
    FontSize["XXXXLarge"] = "48px";
})(FontSize || (FontSize = {}));
export var LineHeight = {
    Small: '100%',
    Medium: '120%',
    Large: '150%',
};
export var Spacing;
(function (Spacing) {
    Spacing["XSmall"] = "4px";
    Spacing["Small"] = "8px";
    Spacing["Medium"] = "16px";
    Spacing["Large"] = "24px";
    Spacing["XLarge"] = "32px";
})(Spacing || (Spacing = {}));
export var HeaderHeight = {
    Mobile: '64px',
    Desk: '80px',
};
export var IconSize;
(function (IconSize) {
    IconSize["Small"] = "12px";
    IconSize["Medium"] = "16px";
    IconSize["Large"] = "20px";
})(IconSize || (IconSize = {}));
export var FaIconSize;
(function (FaIconSize) {
    FaIconSize["XSmall"] = "xs";
    FaIconSize["Large"] = "lg";
    FaIconSize["Small"] = "sm";
    FaIconSize["X1"] = "1x";
    FaIconSize["X2"] = "2x";
    FaIconSize["X3"] = "3x";
    FaIconSize["X4"] = "4x";
    FaIconSize["X5"] = "5x";
    FaIconSize["X6"] = "6x";
    FaIconSize["X7"] = "7x";
    FaIconSize["X8"] = "8x";
    FaIconSize["X9"] = "9x";
    FaIconSize["X10"] = "10x";
})(FaIconSize || (FaIconSize = {}));
export var DrawerWidth = '256px';
export var Navbar = {
    a: '1',
};
export var FieldHeight = '48px';
export var TransitionDuration = '.3s';
export var Border = {
    Color: Color.GrayLight,
    Width: '1px',
    Radius: '4px',
    RadiusLarge: '20px',
};
// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export var PasswordLength;
(function (PasswordLength) {
    PasswordLength[PasswordLength["Max"] = 18] = "Max";
    PasswordLength[PasswordLength["Min"] = 7] = "Min";
})(PasswordLength || (PasswordLength = {}));
export var AspectRatio;
(function (AspectRatio) {
    AspectRatio[AspectRatio["Square"] = 1] = "Square";
})(AspectRatio || (AspectRatio = {}));
export var Breakpoint = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
};
export var MediaQuery = {
    xs: '@media (min-width: 0)',
    sm: "@media (min-width: ".concat(Breakpoint.sm - 0.2, "px)"),
    md: "@media (min-width: ".concat(Breakpoint.md - 0.2, "px)"),
    lg: "@media (min-width: ".concat(Breakpoint.lg - 0.2, "px)"),
    xl: "@media (min-width: ".concat(Breakpoint.xl - 0.2, "px)"),
    xxl: "@media (min-width: ".concat(Breakpoint.xxl - 0.2, "px)"),
    tablet: "@media (max-width: ".concat(Breakpoint.md + 0.2, "px)"),
};
export var ZIndex;
(function (ZIndex) {
    ZIndex[ZIndex["ground"] = 0] = "ground";
    ZIndex[ZIndex["first"] = 1] = "first";
    ZIndex[ZIndex["second"] = 2] = "second";
    ZIndex[ZIndex["third"] = 3] = "third";
    ZIndex[ZIndex["top"] = 4] = "top";
})(ZIndex || (ZIndex = {}));
export var Shadow = {
    Small: '0px 0px 20px rgba(0, 0, 0, 0.1)',
};
export var media = {
    min: '(min-width: 0px)',
    small: '(min-width: 576px)',
    medium: '(min-width: 768px)',
    large: '(min-width: 992px)',
    xLarge: '(min-width: 1200px)',
    max: '(min-width: 1440px)',
    prefersReducedMotion: '(prefers-reduced-motion)',
};
export var OpacityHover = 0.7;
