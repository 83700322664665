import * as React from 'react';
import { InView } from 'react-intersection-observer';
import { DisableAnimationsContext } from '@app/data/storage/disable-animation.context';
import { AnimatedImageBackgroundStyled, AnimatedImageStyled, AnimationReferenceDivStyled, AnimationWrapperStyled, animatedImageBackgroundClassName, animatedImageClassName, disableAnimationClassName, } from '@atomic/mol.animated-image/animated-image.component.style';
import { MediaQueryContext } from '@atomic/obj.media-query';
export var AnimatedImage = function (_a) {
    var Image = _a.Image, Background = _a.Background, invertAnimation = _a.invertAnimation, negativeBgMargin = _a.negativeBgMargin;
    var disableAnimations = React.useContext(DisableAnimationsContext).value;
    var isMediumOrLarger = React.useContext(MediaQueryContext).isMediumOrLarger;
    var PERCENTAGE_VISIBLE_TO_TRIGGER_ANIMATION = isMediumOrLarger ? 0.5 : 0.3;
    return (React.createElement(InView, { threshold: PERCENTAGE_VISIBLE_TO_TRIGGER_ANIMATION, triggerOnce: true }, function (_a) {
        var inView = _a.inView, ref = _a.ref;
        var getAnimationClassName = function (animationClass) {
            return disableAnimations ? disableAnimationClassName : inView ? animationClass : null;
        };
        return (React.createElement(AnimationWrapperStyled, { ref: ref }, React.createElement(AnimationReferenceDivStyled, null,
            Image && (React.createElement(AnimatedImageStyled, { className: getAnimationClassName(animatedImageClassName) },
                React.createElement(Image, null))),
            Background && (React.createElement(AnimatedImageBackgroundStyled, { className: getAnimationClassName(animatedImageBackgroundClassName), negativeMargin: negativeBgMargin, invertAnimation: invertAnimation },
                React.createElement(Background, null))))));
    }));
};
