var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { HEADER_HEIGHT } from '@app/components/mol.header';
var HERO_HEIGHT = 327;
export var SecondaryHeroSectionStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: ", "px;\n  left: 0;\n  right: 0;\n\n  background-image: ", "\n  height: ", "px;\n  background-position: top;\n  background-repeat: no-repeat;\n  background-size: cover;\n"], ["\n  position: absolute;\n  top: ", "px;\n  left: 0;\n  right: 0;\n\n  background-image: ", "\n  height: ", "px;\n  background-position: top;\n  background-repeat: no-repeat;\n  background-size: cover;\n"])), HEADER_HEIGHT, function (_a) {
    var bgImage = _a.bgImage;
    return "url(".concat(bgImage, ");");
}, HERO_HEIGHT);
export var SecondaryHeroSectionContentStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var SecondaryHeroSectionSpaceStyled = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: ", "px;\n"], ["\n  height: ", "px;\n"])), HERO_HEIGHT / 5);
var templateObject_1, templateObject_2, templateObject_3;
