import Thumb1 from './img/client-testimony-1.png?as=webp';
import Thumb2 from './img/client-testimony-2.png?as=webp';
import Thumb3 from './img/client-testimony-3.jpg?as=webp';
export var clientTestimonies = [
    {
        name: 'Alessandra Malheiros',
        role: 'Portal de Delfos',
        thumb: Thumb1,
        text: 'O desenvolvimento do Portal de Delfos com o time do instituto tem sido uma experiência incrível! Muito além das competências técnicas, percebo o interesse genuíno de todos, boa energia do grupo e a capacidade de desafiar e expandir perspectivas, o que contribui e muito para toda a experiência!',
    },
    {
        name: 'Igor Girsas',
        role: 'Head of Product & Growth da Pill Farmácia Digital',
        thumb: Thumb2,
        text: 'A experiência com o Instituto não poderia ter sido melhor, com toda certeza vamos fazer mais projetos. Os times são extremamente bem mentorados e os sponsors estão sempre presentes no projeto, garantindo que as entregas tenham a qualidade muito acima da média.',
    },
    {
        name: 'Marcelo Lima dos Santos',
        role: 'Organizador da Senec',
        thumb: Thumb3,
        text: 'A parceria com o time do Instituto foi uma opção que rendeu ótimos frutos, a atenção de vocês nas nossas negociações e desenvolvimento no projeto foi um grande diferencial, sempre se preocupando em nos entender em primeiro lugar e com muita disposição para expandir as opções e surpreendendo na sua execução. Por isso agradecemos muito a presteza de toda a equipe ao longo dessa experiência.',
    },
];
