var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { AppAssets } from '@app/components/atm.app-assets/app-assets';
import { MediaQuery, Spacing } from '@atomic/obj.constants';
var bgStyleMapper = {
    primary: 'background: linear-gradient(180deg, #cdfef7 0%, rgba(205, 254, 247, 0) 100%);',
    white: 'background: white;',
};
export var ArchBg = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  padding-top: ", ";\n  border-radius: 0px 800px 0px 0px;\n  ", "\n"], ["\n  position: relative;\n  padding-top: ", ";\n  border-radius: 0px 800px 0px 0px;\n  ", "\n"])), Spacing.Large, function (_a) {
    var bgColor = _a.bgColor;
    return bgStyleMapper[bgColor];
});
export var LeftSideBg = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  right: 20%;\n  transform: translate(-60%, -5%);\n\n  ", " {\n    right: 50%;\n    top: 0;\n    transform: translate(-60%, -5%);\n  }\n\n  ", " {\n    right: 50%;\n    top: 0;\n    transform: translate(-300px, -5%);\n  }\n"], ["\n  position: absolute;\n  right: 20%;\n  transform: translate(-60%, -5%);\n\n  ", " {\n    right: 50%;\n    top: 0;\n    transform: translate(-60%, -5%);\n  }\n\n  ", " {\n    right: 50%;\n    top: 0;\n    transform: translate(-300px, -5%);\n  }\n"])), MediaQuery.md, MediaQuery.xl);
export var RightSideBg = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  left: 20%;\n  top: 10%;\n  transform: translate(60%, 0%);\n\n  ", " {\n    left: 50%;\n    top: 0;\n    transform: translate(60%, 0%);\n  }\n\n  ", " {\n    left: 50%;\n    top: 0;\n    transform: translate(300px, 0%);\n  }\n"], ["\n  position: absolute;\n  left: 20%;\n  top: 10%;\n  transform: translate(60%, 0%);\n\n  ", " {\n    left: 50%;\n    top: 0;\n    transform: translate(60%, 0%);\n  }\n\n  ", " {\n    left: 50%;\n    top: 0;\n    transform: translate(300px, 0%);\n  }\n"])), MediaQuery.md, MediaQuery.xl);
export var SpaceBg = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: top;\n"], ["\n  position: relative;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: top;\n"])), AppAssets.Image.BgSpace);
export var EllipseBg = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: top;\n"], ["\n  position: relative;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: top;\n"])), AppAssets.Image.BgEllipse);
export var ImpactFront = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 75vw;\n  transform: translate(20%, 20%);\n\n  ", " {\n    transform: translate(15%, 0);\n  }\n"], ["\n  max-width: 75vw;\n  transform: translate(20%, 20%);\n\n  ", " {\n    transform: translate(15%, 0);\n  }\n"])), MediaQuery.md);
export var ImpactBg = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  max-width: 50vw;\n  transform: translate(-20%, 0);\n\n  ", " {\n    max-width: 30vw;\n    transform: translate(-20%, 0);\n  }\n"], ["\n  max-width: 50vw;\n  transform: translate(-20%, 0);\n\n  ", " {\n    max-width: 30vw;\n    transform: translate(-20%, 0);\n  }\n"])), MediaQuery.md);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
