import React from 'react';
import { Button } from '@atomic/atm.button';
import { MdScreenAndAboveOnly, SmScreenAndBelowOnly, } from '@atomic/atm.media-query-wrapper/media-query-wrapper.component.style';
import { Body, H2 } from '@atomic/atm.typography';
import { AnimatedImage } from '@atomic/mol.animated-image/animated-image.component';
import { Separator } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Col, MaxWidthContainer, Row } from '@atomic/obj.grid';
import { LastSectionImageWrapperStyled } from './last-section.component.style';
export var LastSection = function (props) {
    var Content = (React.createElement(React.Fragment, null,
        React.createElement(MaxWidthContainer, null,
            React.createElement(H2, null, props.title),
            React.createElement(Separator, { size: Spacing.Medium }),
            React.createElement(Body, null, props.description),
            React.createElement(Separator, null),
            React.createElement(Button, { variant: 'primary', href: props.href }, props.button),
            React.createElement(Separator, null))));
    return (React.createElement(React.Fragment, null,
        React.createElement(MdScreenAndAboveOnly, null,
            React.createElement(MaxWidthContainer, null,
                React.createElement(Row, { align: 'center' },
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(H2, null, props.title),
                        React.createElement(Separator, { size: Spacing.Medium }),
                        React.createElement(Body, null, props.description),
                        React.createElement(Separator, null),
                        React.createElement(Button, { variant: 'primary', href: props.href }, props.button),
                        React.createElement(Separator, null)),
                    React.createElement(Col, { xs: 12, md: 6 },
                        React.createElement(LastSectionImageWrapperStyled, null,
                            React.createElement(AnimatedImage, { negativeBgMargin: '0', Background: props.Background, Image: props.Image })))))),
        React.createElement(SmScreenAndBelowOnly, null,
            Content,
            React.createElement(AnimatedImage, { negativeBgMargin: '0', Background: props.Background, Image: props.Image }))));
};
