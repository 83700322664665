import * as React from 'react';
import { NavMenu } from '@app/components/atm.nav-menu';
import { AccessibilityMenu } from '@atomic/obj.accessibility-menu';
import { HeaderStyled } from './header.component.style';
export var HEADER_HEIGHT = 44 + 48 + 16 + 16;
export var Header = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { id: 'accessibilityHeader' },
            React.createElement(AccessibilityMenu, null)),
        React.createElement(HeaderStyled, { id: 'navigationHeader' },
            React.createElement(NavMenu, null))));
};
