var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Color, Spacing } from '@atomic/obj.constants';
var PAGER_BULLET_SIZE = Spacing.Medium;
export var CarouselPagerBulletStyled = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  border: 2px solid ", ";\n  background-color: ", ";\n  cursor: pointer;\n  padding: 0;\n  &:hover {\n    background-color: ", ";\n    border-color: ", ";\n  }\n"], ["\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  border: 2px solid ", ";\n  background-color: ", ";\n  cursor: pointer;\n  padding: 0;\n  &:hover {\n    background-color: ", ";\n    border-color: ", ";\n  }\n"])), PAGER_BULLET_SIZE, PAGER_BULLET_SIZE, Color.Black, function (props) { return (props.active ? Color.Black : Color.White); }, Color.PrimaryLight, Color.PrimaryLight);
export var CarouselPagerStyled = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: ", ";\n  padding: 0;\n  list-style: none;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: ", ";\n  padding: 0;\n  list-style: none;\n"])), Spacing.Small);
export var CarouselPagerItemStyled = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &:not(:first-child) {\n    margin-left: 4px;\n  }\n"], ["\n  &:not(:first-child) {\n    margin-left: 4px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
