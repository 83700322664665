import * as React from 'react';
import { AppIcon } from '@app/components/atm.app-icon';
import { FooterAnchorStyled, FooterItemInlineStyled, FooterItemListStyled, FooterItemStyled, FooterStyled, } from '@app/components/mol.footer/footer.component.style';
import { footerStrings } from '@app/components/mol.footer/footer.strings';
import { Button } from '@atomic/atm.button';
import { BodySecondary, H2 } from '@atomic/atm.typography';
import { LandmarksIds } from '@atomic/mol.accessibility';
import { Col, MaxWidthContainer, Row } from '@atomic/obj.grid';
export var Footer = function () {
    return (React.createElement(FooterStyled, { id: LandmarksIds.Footer },
        React.createElement(MaxWidthContainer, null,
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, sm: 6 },
                    React.createElement(H2, null, footerStrings.contact.title),
                    React.createElement(FooterItemListStyled, null,
                        React.createElement(FooterItemStyled, null,
                            React.createElement(Button, { variant: 'link', href: footerStrings.contact.addressLink, target: '_blank' },
                                React.createElement(BodySecondary, null, footerStrings.contact.address))),
                        React.createElement(FooterItemStyled, null,
                            React.createElement(Button, { variant: 'link', href: footerStrings.contact.phoneLink, target: '_blank' },
                                React.createElement(BodySecondary, null, footerStrings.contact.phone))),
                        React.createElement(FooterItemStyled, null,
                            React.createElement(Button, { variant: 'link', href: footerStrings.contact.emailLink, target: '_blank' },
                                React.createElement(BodySecondary, null, footerStrings.contact.email))))),
                React.createElement(Col, { xs: 12, sm: 6 },
                    React.createElement(H2, null, footerStrings.social.title),
                    React.createElement(FooterItemListStyled, null,
                        React.createElement(FooterItemInlineStyled, null,
                            React.createElement(FooterAnchorStyled, { "aria-label": footerStrings.social.linkedin.label, href: footerStrings.social.linkedin.link, target: '_blank' },
                                React.createElement(AppIcon.LinkedIn, null))),
                        React.createElement(FooterItemInlineStyled, null,
                            React.createElement(FooterAnchorStyled, { "aria-label": footerStrings.social.instagram.label, href: footerStrings.social.instagram.link, target: '_blank' },
                                React.createElement(AppIcon.Instagram, null))),
                        React.createElement(FooterItemInlineStyled, null,
                            React.createElement(FooterAnchorStyled, { "aria-label": footerStrings.social.medium.label, href: footerStrings.social.medium.link, target: '_blank' },
                                React.createElement(AppIcon.Medium, null)))),
                    React.createElement(FooterItemListStyled, null,
                        React.createElement(FooterItemStyled, null,
                            React.createElement(Button, { variant: 'link', href: footerStrings.social.openJobs.link, target: '_blank' },
                                React.createElement(BodySecondary, null, footerStrings.social.openJobs.label)))))))));
};
