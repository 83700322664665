import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { InputLabelChildren, InputLabelStyled, InputLabelWrapperStyled } from './input-label.component.style';
export var InputLabel = function (props) {
    var context = React.useContext(FormFieldContext);
    if (!context) {
        throw new Error('InputLabel must be within a FormFieldContext provider');
    }
    return (React.createElement(InputLabelStyled, null,
        React.createElement(InputLabelChildren, null, props.children),
        React.createElement(InputLabelWrapperStyled, { filled: context.value && context.value !== '', htmlFor: context.name }, props.label)));
};
