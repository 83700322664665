var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { FormFieldContext } from '@atomic/obj.form';
import { TextAreaStyled } from './text-area-field.component.style';
var TextAreaField = /** @class */ (function (_super) {
    __extends(TextAreaField, _super);
    function TextAreaField(props) {
        var _this = this;
        var _a;
        _this = _super.call(this, props) || this;
        _this.handleTextChange = function (event) {
            var _a, _b, _c;
            if (_this.isControlled()) {
                return;
            }
            var value = event.currentTarget.value;
            if (((_a = _this.formFieldConsumer) === null || _a === void 0 ? void 0 : _a.value) === value) {
                return;
            }
            (_c = (_b = _this.props).onValueChange) === null || _c === void 0 ? void 0 : _c.call(_b, value);
            if (_this.formFieldConsumer) {
                _this.formFieldConsumer.onValueChange(value, null);
            }
            else {
                _this.setState({ value: value });
            }
        };
        _this.isControlled = function () { return _this.props.value !== undefined; };
        _this.state = {
            value: (_a = props.initialValue) !== null && _a !== void 0 ? _a : props.value,
        };
        return _this;
    }
    TextAreaField.prototype.componentDidMount = function () {
        if (this.isControlled() && this.props.initialValue !== undefined) {
            throw new Error('Use either the initialValue prop, or the value prop, but not both');
        }
        if (this.formFieldConsumer) {
            if (this.isControlled() || this.props.initialValue !== undefined) {
                if (this.formFieldConsumer.value) {
                    throw new Error('Please, use either value props in <TextField> or <Form.Field> component.');
                }
                this.formFieldConsumer.onValueChange(this.state.value, null);
            }
        }
        else if (!this.props['aria-label'] && !this.props['aria-labelledby'] && !this.props.id) {
            throw new Error('Please, identify field for acessibility.');
        }
    };
    TextAreaField.prototype.componentDidUpdate = function (prevProps) {
        var _a, _b, _c;
        if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.value);
            (_c = this.formFieldConsumer) === null || _c === void 0 ? void 0 : _c.onValueChange(this.props.value, null);
            this.setState({ value: this.props.value });
        }
    };
    TextAreaField.prototype.render = function () {
        var _this = this;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var _a = this.props, onValueChange = _a.onValueChange, onChange = _a.onChange, value = _a.value, initialValue = _a.initialValue, other = __rest(_a, ["onValueChange", "onChange", "value", "initialValue"]);
        return (React.createElement(FormFieldContext.Consumer, null, function (formFieldConsumer) {
            var _a, _b, _c, _d, _e, _f;
            _this.formFieldConsumer = formFieldConsumer;
            var val = (_a = formFieldConsumer === null || formFieldConsumer === void 0 ? void 0 : formFieldConsumer.value) !== null && _a !== void 0 ? _a : _this.state.value;
            return (React.createElement(TextAreaStyled, __assign({ value: val !== null && val !== void 0 ? val : '', onChange: _this.handleTextChange, id: (_b = _this.formFieldConsumer) === null || _b === void 0 ? void 0 : _b.name, "aria-labelledby": "".concat((_c = _this.formFieldConsumer) === null || _c === void 0 ? void 0 : _c.name, "-label ").concat((_d = _this.formFieldConsumer) === null || _d === void 0 ? void 0 : _d.name, "-caption"), "aria-invalid": ((_f = (_e = _this.formFieldConsumer) === null || _e === void 0 ? void 0 : _e.errors) === null || _f === void 0 ? void 0 : _f.length) > 0 }, other)));
        }));
    };
    return TextAreaField;
}(React.Component));
export { TextAreaField };
