import * as React from 'react';
import { DisableAnimationsContext } from '@app/data/storage/disable-animation.context';
var hasWindow = function () { return typeof window !== 'undefined'; };
export var usePrefersReducedMotion = function () {
    var _a = React.useState(), prefersReducedMotion = _a[0], setPrefersReducedMotion = _a[1];
    var disableAnimations = React.useContext(DisableAnimationsContext).value;
    React.useEffect(function () {
        if (hasWindow()) {
            var mediaQuery_1 = window.matchMedia('(prefers-reduced-motion: reduce)');
            setPrefersReducedMotion(mediaQuery_1.matches);
            var prefersReducedMotionChanged_1 = function () {
                setPrefersReducedMotion(mediaQuery_1.matches);
            };
            mediaQuery_1.addEventListener('change', prefersReducedMotionChanged_1);
            return function () {
                mediaQuery_1.removeEventListener('change', prefersReducedMotionChanged_1);
            };
        }
    }, []);
    return { prefersReducedMotion: prefersReducedMotion || disableAnimations };
};
