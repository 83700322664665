import * as React from 'react';
import { usePath } from '@app/core/route';
import { DisableAnimationsContext } from '@app/data/storage/disable-animation.context';
import { IcClose, IcLogo } from '@assets/icons';
import { Button } from '@atomic/atm.button';
import { Drawer } from '@atomic/atm.drawer';
import { useKeyboardEscape } from '@atomic/atm.keyboard/keyboard.hook';
import { ToggleButton } from '@atomic/atm.toggle-button/toggle-button.component';
import { BodySecondary, Label } from '@atomic/atm.typography';
import { LandmarksIds } from '@atomic/mol.accessibility';
import { VisuallyHidden } from '@atomic/mol.accessibility/visually-hidden.component';
import { accessibilityMenuStrings } from '@atomic/obj.accessibility-menu/accessibility-menu.strings';
import { Hbox, Separator } from '@atomic/obj.box';
import { AccessibilityMenuMobileItemListStyled, AccessibilityMenuMobileItemStyled, } from './accessibility-menu-mobile.component.style';
export var AccessibilityMenuMobile = function (props) {
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var accessibilityButtonRef = React.useRef();
    var addToPath = usePath().addToPath;
    var handleClose = React.useCallback(function () {
        var _a;
        (_a = accessibilityButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        setOpen(false);
    }, []);
    useKeyboardEscape(handleClose);
    var handleLinkTap = function () {
        setOpen(false);
        return true;
    };
    return (React.createElement("nav", { id: LandmarksIds.Menu.Accessibility, "aria-labelledby": BUTTON_ID },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true },
                React.createElement(Button, { ref: accessibilityButtonRef, id: BUTTON_ID, onClick: function () { return setOpen(true); }, "aria-expanded": open },
                    React.createElement(VisuallyHidden, null, "Menu de "),
                    React.createElement(BodySecondary, null, "Acessibilidade")))),
        React.createElement(Drawer, { from: 'left', active: open },
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, null,
                    React.createElement(IcLogo, null)),
                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                    React.createElement(Button, { round: true, onClick: handleClose, "aria-label": 'Fechar menu' },
                        React.createElement(IcClose, { style: { verticalAlign: 'middle' } })))),
            React.createElement(Separator, null),
            React.createElement(DisableAnimationsContext.Consumer, null, function (_a) {
                var value = _a.value, updateValue = _a.updateValue;
                return (React.createElement(ToggleButton, { label: accessibilityMenuStrings.animations, value: !value, onToggle: function () { return updateValue(!value); } }));
            }),
            React.createElement(Separator, null),
            React.createElement(AccessibilityMenuMobileItemListStyled, null, props.items.map(function (item) { return (React.createElement(AccessibilityMenuMobileItemStyled, { key: item.label },
                React.createElement(Button, { href: addToPath(item.href), onClick: handleLinkTap },
                    React.createElement(Label, null, item.label)))); })))));
};
var BUTTON_ID = 'acessibility-button';
