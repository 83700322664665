import * as React from 'react';
import { useMatch } from '@app/core/route';
import { IcClose, IcLogo, IcMenu } from '@assets/icons';
import { Button } from '@atomic/atm.button';
import { Drawer } from '@atomic/atm.drawer';
import { useKeyboardEscape } from '@atomic/atm.keyboard/keyboard.hook';
import { Label } from '@atomic/atm.typography';
import { LandmarksIds } from '@atomic/mol.accessibility';
import { Hbox, Separator } from '@atomic/obj.box';
import { NavMenuMobileItemListStyled, NavMenuMobileItemStyled, NavMenuMobileStyled, } from './nav-menu-mobile.component.style';
export var NavMenuMobile = function (props) {
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var menuButtonRef = React.useRef();
    var handleClose = React.useCallback(function () {
        var _a;
        (_a = menuButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        setOpen(false);
    }, []);
    useKeyboardEscape(handleClose);
    var handleLinkTap = function () {
        handleClose();
        return true;
    };
    var match = useMatch().match;
    return (React.createElement(NavMenuMobileStyled, { id: LandmarksIds.Menu.Navigation, "aria-labelledby": BUTTON_ID },
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, null,
                React.createElement(Button, { to: '/', "aria-label": 'Instituto Taqtile. Ir para a Home', "aria-current": match('/') ? 'page' : undefined },
                    React.createElement(IcLogo, null))),
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center', hAlign: 'flex-end' },
                React.createElement(Button, { round: true, ref: menuButtonRef, onClick: function () { return setOpen(true); }, "aria-label": 'Menu' },
                    React.createElement(IcMenu, null)))),
        React.createElement(Drawer, { from: 'right', active: open, color: 'PrimaryXLight' },
            React.createElement(Hbox, null,
                React.createElement(Hbox.Item, null,
                    React.createElement(IcLogo, null)),
                React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                    React.createElement(Button, { round: true, onClick: handleClose, "aria-label": 'Fechar menu' },
                        React.createElement(IcClose, { style: { verticalAlign: 'middle' } })))),
            React.createElement(Separator, null),
            React.createElement(NavMenuMobileItemListStyled, null, props.items.map(function (item) {
                var current = match(item.href);
                return (React.createElement(NavMenuMobileItemStyled, { key: item.label },
                    React.createElement(Button, { to: item.href, onClick: handleLinkTap, "aria-current": current ? 'page' : undefined },
                        React.createElement(Label, { bold: current }, item.label))));
            })))));
};
var BUTTON_ID = 'menu-button';
