import * as React from 'react';
export var useCarouselFocus = function (_a) {
    var firstIndex = _a.firstIndex;
    var itemsRef = React.useRef({});
    var isShowingAllItems = React.useRef();
    // show all items for animation
    var showItems = React.useCallback(function () {
        if (!isShowingAllItems.current) {
            Object.values(itemsRef.current).forEach(function (item) {
                if (item === null || item === void 0 ? void 0 : item.style) {
                    item.style.visibility = 'visible';
                }
            });
            isShowingAllItems.current = true;
        }
    }, []);
    // show only current item so other items can't be focused
    var showOnlyItem = React.useCallback(function (_a) {
        var index = _a.index, withFocus = _a.withFocus;
        Object.keys(itemsRef.current).forEach(function (key) {
            var _a, _b, _c, _d, _e;
            if ((_a = itemsRef.current[key]) === null || _a === void 0 ? void 0 : _a.style) {
                itemsRef.current[key].style.visibility = index === +key ? 'visible' : 'hidden';
            }
            if (withFocus && index === +key) {
                var firstChild = (_b = itemsRef.current[key]) === null || _b === void 0 ? void 0 : _b.firstChild;
                if (firstChild && (firstChild.nodeName === 'A' || firstChild.nodeName === 'BUTTON')) {
                    firstChild.focus();
                }
                else {
                    (_c = itemsRef.current[key]) === null || _c === void 0 ? void 0 : _c.setAttribute('tabindex', '-1');
                    (_d = itemsRef.current[key]) === null || _d === void 0 ? void 0 : _d.focus();
                }
            }
            else {
                (_e = itemsRef.current[key]) === null || _e === void 0 ? void 0 : _e.removeAttribute('tabindex');
            }
        });
        isShowingAllItems.current = false;
    }, []);
    React.useEffect(function () {
        if (isShowingAllItems.current === undefined) {
            isShowingAllItems.current = true;
            showOnlyItem({ index: firstIndex });
        }
    }, [firstIndex, showOnlyItem]);
    return { itemsRef: itemsRef, showItems: showItems, showOnlyItem: showOnlyItem };
};
