import * as React from 'react';
import { media } from '@atomic/obj.constants';
/**
 * Use this component to watch a media query.
 *
 * This can be useful if you need to change a layout according to
 * media-queries, but you don't want to use css (you want to do it using
 * javascript). For instance, you want this if your mobile layout is completely
 * different from you desktop layout.
 */
export var useMediaQuery = function (minWidth) {
    var _a = React.useState(false), targetReached = _a[0], setTargetReached = _a[1];
    var updateTarget = React.useCallback(function (event) {
        setTargetReached(event.matches);
    }, []);
    React.useEffect(function () {
        if (typeof window !== 'undefined') {
            var chosenMedia_1 = window.matchMedia(media[minWidth !== null && minWidth !== void 0 ? minWidth : 'medium']);
            chosenMedia_1.addEventListener('change', updateTarget);
            if (chosenMedia_1.matches) {
                setTargetReached(true);
            }
            return function () { return chosenMedia_1.removeEventListener('change', updateTarget); };
        }
    }, [minWidth, updateTarget]);
    return targetReached;
};
