var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/display-name */
import React from 'react';
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBus } from '@fortawesome/free-solid-svg-icons/faBus';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons/faDotCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons/faMicrophone';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStarHalf } from '@fortawesome/free-solid-svg-icons/faStarHalf';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line @typescript-eslint/no-namespace
export var FaIcon;
(function (FaIcon) {
    FaIcon.Ban = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBan })); };
    FaIcon.Bus = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBus })); };
    FaIcon.Circle = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCircle })); };
    FaIcon.Check = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCheck })); };
    FaIcon.CheckSquare = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCheckSquare })); };
    FaIcon.ChevronDown = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronDown })); };
    FaIcon.ChevronLeft = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronLeft })); };
    FaIcon.ChevronRight = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronRight })); };
    FaIcon.ChevronUp = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faChevronUp })); };
    FaIcon.Close = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faTimes })); };
    FaIcon.DotCircle = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faDotCircle })); };
    FaIcon.FileUpload = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faUpload })); };
    FaIcon.FlashAlert = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faTimesCircle })); };
    FaIcon.FlashInfo = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faInfoCircle })); };
    FaIcon.FlashSuccess = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faCheckCircle })); };
    FaIcon.FlashWarning = function (props) { return (React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faExclamationCircle }))); };
    FaIcon.Heart = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faHeart })); };
    FaIcon.Image = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faImage })); };
    FaIcon.Menu = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faBars })); };
    FaIcon.Microphone = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faMicrophone })); };
    FaIcon.Search = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faSearch })); };
    FaIcon.Square = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faSquare })); };
    FaIcon.Star = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faStar })); };
    FaIcon.StarHalf = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faStarHalf })); };
    FaIcon.StepperMinus = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faMinus })); };
    FaIcon.StepperPlus = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faPlus })); };
    FaIcon.Warning = function (props) { return React.createElement(FontAwesomeIcon, __assign({}, props, { icon: faExclamationTriangle })); };
})(FaIcon || (FaIcon = {}));
