/* eslint-disable react/display-name */
import * as React from 'react';
import ArrowRight from '@assets/icons/custom/ic_arrow_right.svg';
import Briefcase from '@assets/icons/custom/ic_briefcase.svg';
import ExternalLink from '@assets/icons/custom/ic_external_link.svg';
import Group from '@assets/icons/custom/ic_group.svg';
import Handshake from '@assets/icons/custom/ic_handshake.svg';
import Instagram from '@assets/icons/custom/ic_instagram.svg';
import LinkedIn from '@assets/icons/custom/ic_linkedin.svg';
import Medium from '@assets/icons/custom/ic_medium.svg';
import Logo from '@assets/icons/favicon.png?as=webp';
import LoadingTooltipArrow from '@assets/img/img_tooltip_arrow.png?as=webp';
export var AppIcon = {
    ArrowRight: function () { return React.createElement("img", { alt: '', src: ArrowRight }); },
    Briefcase: function () { return React.createElement("img", { alt: '', src: Briefcase }); },
    ExternalLink: function () { return React.createElement("img", { alt: '', src: ExternalLink }); },
    Group: function () { return React.createElement("img", { alt: '', src: Group }); },
    Handshake: function () { return React.createElement("img", { alt: '', src: Handshake }); },
    Instagram: function () { return React.createElement("img", { alt: 'Instagram', src: Instagram }); },
    LinkedIn: function () { return React.createElement("img", { alt: 'LinkedIn', src: LinkedIn }); },
    Medium: function () { return React.createElement("img", { alt: 'Medium', src: Medium }); },
    LoadingTooltipArrow: function () { return React.createElement("img", { alt: '', src: LoadingTooltipArrow, width: '40px' }); },
    Logo: function () { return React.createElement("img", { alt: '', src: Logo, width: '40px' }); },
};
