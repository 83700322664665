import * as React from 'react';
import { useMatch } from '@app/core/route';
import { IcLogoBig } from '@assets/icons';
import { Button } from '@atomic/atm.button';
import { Label } from '@atomic/atm.typography';
import { LandmarksIds } from '@atomic/mol.accessibility';
import { Hbox } from '@atomic/obj.box';
import { NavMenuDesktopItemListStyled, NavMenuDesktopItemStyled, NavMenuDesktopStyled, } from './nav-menu-desktop.component.style';
export var NavMenuDesktop = function (props) {
    var match = useMatch().match;
    return (React.createElement(NavMenuDesktopStyled, null,
        React.createElement(Hbox, null,
            React.createElement(Hbox.Item, { noGrow: true, vAlign: 'center' },
                React.createElement(Button, { to: '/', "aria-label": 'Instituto Taqtile. Ir para a Home', "aria-current": match('/') ? 'page' : undefined },
                    React.createElement(IcLogoBig, null))),
            React.createElement(Hbox.Item, { hAlign: 'flex-end' },
                React.createElement("nav", { id: LandmarksIds.Menu.Navigation, "aria-label": 'Menu principal' },
                    React.createElement(NavMenuDesktopItemListStyled, null, props.items.map(function (_a) {
                        var label = _a.label, href = _a.href;
                        var current = match(href);
                        return (React.createElement(NavMenuDesktopItemStyled, { key: label, current: current },
                            React.createElement(Button, { to: href, "aria-current": current ? 'page' : undefined },
                                React.createElement(Label, { bold: current }, label))));
                    })))))));
};
