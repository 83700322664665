import * as React from 'react';
export var useThrottle = function (fn, delay) {
    var fnRef = React.useRef(fn);
    var lastCalledTimestamp = React.useRef();
    React.useEffect(function () {
        fnRef.current = fn;
    }, [fn]);
    var throttledFn = React.useCallback(function () {
        var hasNeverBeenCalled = lastCalledTimestamp.current === undefined;
        var hasPassedDelay = Date.now() - lastCalledTimestamp.current >= delay;
        if (hasNeverBeenCalled || hasPassedDelay) {
            fnRef.current();
            lastCalledTimestamp.current = Date.now();
        }
    }, [delay]);
    return throttledFn;
};
