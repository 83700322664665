var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { MediaQuery, Spacing } from '@atomic/obj.constants';
var THUMB_SIZE = {
    SMALL: 104,
    LARGE: 200,
};
var SpacingLarge = 32;
var SpacingMedium = 16;
var ButtonSize = 44;
export var TestimonyCardWrapperStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 842px;\n  padding: ", ";\n  padding-top: ", "px;\n\n  ", " {\n    padding: ", "px;\n    padding-top: ", "px;\n  }\n\n  ", " {\n    padding: ", ";\n    padding-top: ", "px;\n  }\n"], ["\n  max-width: 842px;\n  padding: ", ";\n  padding-top: ", "px;\n\n  ", " {\n    padding: ", "px;\n    padding-top: ", "px;\n  }\n\n  ", " {\n    padding: ", ";\n    padding-top: ", "px;\n  }\n"])), Spacing.Medium, THUMB_SIZE.SMALL / 2, MediaQuery.md, ButtonSize + SpacingLarge + SpacingMedium, THUMB_SIZE.SMALL / 2, MediaQuery.lg, Spacing.XLarge, THUMB_SIZE.SMALL / 2);
export var TestimonyCardBodyStyled = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", ";\n\n  ", " {\n    padding: 80px 140px;\n  }\n"], ["\n  padding: ", ";\n\n  ", " {\n    padding: 80px 140px;\n  }\n"])), Spacing.XLarge, MediaQuery.xl);
export var TestimonyCardThumbStyled = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  left: calc(50% - ", "px);\n  top: -", "px;\n\n  width: ", "px;\n  height: ", "px;\n\n  ", " {\n    left: -", "px;\n    top: 0;\n\n    width: ", "px;\n    height: ", "px;\n  }\n\n  border-radius: 50%;\n"], ["\n  position: absolute;\n  left: calc(50% - ", "px);\n  top: -", "px;\n\n  width: ", "px;\n  height: ", "px;\n\n  ", " {\n    left: -", "px;\n    top: 0;\n\n    width: ", "px;\n    height: ", "px;\n  }\n\n  border-radius: 50%;\n"])), THUMB_SIZE.SMALL / 2, THUMB_SIZE.SMALL / 2, THUMB_SIZE.SMALL, THUMB_SIZE.SMALL, MediaQuery.xl, THUMB_SIZE.LARGE / 2, THUMB_SIZE.LARGE, THUMB_SIZE.LARGE);
export var TestimonyCardQuotationMarkStyled = styled.img.attrs({
    alt: '',
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 32px;\n  height: 32px;\n"], ["\n  width: 32px;\n  height: 32px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
