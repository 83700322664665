export var designCourseStrings = {
    title: 'Capacitação em UX/UI Design',
    aboutInstaq: {
        title: 'Sobre o Instaq - Instituto Taqtile',
        content: 'O Instaq - Instituto Taqtile é uma instituição social que tem como propósito criar um ciclo de oportunidades entre jovens talentosos que enfrentam barreiras socioeconômicas e empresas comprometidas em oferecer um retorno social positivo. Nós oferecemos um treinamento técnico nas áreas de design e programação, além de um acompanhamento individualizado para desenvolvimento de soft skills, visando preparar esses jovens para o mercado de trabalho. Nosso programa se inicia com um período mais intenso de capacitação (aproximadamente 1 mês), seguido de uma fase de atuação prática em um projeto com clientes reais (fase esta que pode vir a durar até 3 anos).',
    },
    aboutCourse: {
        title: 'Sobre a Capacitação',
        content: 'A primeira etapa oferece uma introdução aos princípios e práticas de UX/UI Design, passando por todas as fases de um projeto até sua entrega para os desenvolvedores. Os alunos aprendem a criar experiências de usuário intuitivas e interfaces atraentes, utilizando ferramentas comuns ao mercado. O treinamento é oferecido pela designer Paola R. Fernandes, profissional da área com mais de 13 anos de experiência, sendo 6 deles dedicados à área de produtos digitais.',
    },
    goals: {
        title: 'Objetivos',
        content: [
            'Compreender os fundamentos do design centrado no usuário;',
            'Desenvolver habilidades em arquitetura da informação, wireframing e prototipagem;',
            'Aprender a realizar pesquisas de imersão sobre o usuário, cliente e segmento de atuação, além de aplicar testes de usabilidade;',
            'Criar interfaces visuais atraentes e funcionais;',
            'Desenvolver uma documentação, em formato de Style Guide, para handoff aos desenvolvedores.',
        ],
    },
    course: {
        title: 'Estrutura do Curso',
        week1: {
            title: 'Semana 1: Introdução ao UX/UI Design',
            content: [
                'O que é Design e como se diferencia de outras áreas de conhecimento;',
                'Fundamentos e princípios de design e composição;',
                'Introdução a usabilidade, heurísticas e leis da psicologia aplicadas ao design;',
                'Introdução ao Design Thinking e ao conceito de Double Diamond;',
                'Exercícios práticos de fixação dos conceitos, com discussão e revisão da instrutora.',
            ],
        },
        week2: {
            title: 'Semana 2: Entendimento e Definição',
            content: [
                'Introdução a Definição de Problemas;',
                'Métodos de pesquisa: desk research, benchmarks e tipos de entrevistas;',
                'Personas, frameworks (user needs, mapa de empatia) e jornada do usuário;',
                'Introdução à Arquitetura de Informação e Wireframes;',
                'Treinamento em ferramentas de wireframing (Visily, Miro);',
                'Exercícios práticos de fixação dos conceitos, com discussão e revisão da instrutora.',
            ],
        },
        week3: {
            title: 'Semana 3: Elaboração',
            content: [
                'Conceito de Atomic Design e sua aplicação;',
                'Style tiles x Style Guide x Design System;',
                'Definição e aplicação da identidade visual;',
                'Conceito de consistência e padrões de navegação;',
                'Treinamento em Figma (introdução à ferramenta, recursos e configuração inicial);',
                'Princípios de design visual: cor, tipografia, espaçamento;',
                'Criação de um Style Guide;',
                'Exercícios práticos de fixação dos conceitos, com discussão e revisão da instrutora.',
            ],
        },
        week4: {
            title: 'Semana 4: Validação',
            content: [
                'Tipos de técnicas de validação;',
                'Condução de testes de usabilidade;',
                'Consolidação dos resultados;',
                'Dicas para apresentação com storytelling;',
                'Dicas sobre como interagir com clientes;',
                'Documentação dos aprendizados;',
                'Exercícios práticos de fixação dos conceitos, com discussão e revisão da instrutora.',
            ],
        },
        week5: {
            title: 'Semana 5: Conclusão',
            content: [
                'O que é e como fazer um handoff para desenvolvedores;',
                'Revisão dos aprendizados e dicas extras;',
                'Bônus: acessibilidade e UX Writing;',
                'Apresentação do projeto e feedback.',
            ],
        },
        week6: {
            title: 'Semana 6 em diante: Aplicação prática',
            content: 'Após o período de onboarding, o aluno é alocado em um projeto real, acompanhado pela instrutora e outros mentores, onde poderá colocar em prática o conteúdo aprendido. A partir desse momento, o treinamento é realizado na forma de sessões semanais de mentoria, além das rotinas do projeto (ex: diários, críticas de design, etc.)',
        },
    },
    aditionalResources: {
        title: 'Recursos adicionais',
        books: {
            title: 'Referências bibliográficas',
            content: [
                '"Don\'t Make Me Think" - Steve Krug',
                '"Rocket Surgery Made Easy" - Steve Krug',
                '"The Design of Everyday Things" - Don Norman',
                '"Design is Storytelling" - Ellen Lupton',
                '"Laws of UX: Using Psychology to Design Better Products & Services" - Jon Yablonski ',
                '"The Mom Test" - Rob Fitzpatrick',
                '"The elements of user experience" - Jesse Garrett',
            ],
        },
        sites: {
            title: 'Websites úteis',
            content: [
                'Growth.Design',
                'Human Interface Guidelines',
                'Laws of UX',
                'Material Design',
                'Nielsen Norman Group',
                'UX Collective',
                'UX Movement',
                'UX Planet',
                'Smashing Magazine',
            ],
        },
    },
};
