var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Border, Color } from '@atomic/obj.constants';
export var NavMenuMobileStyled = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-grow: 1;\n"], ["\n  flex-grow: 1;\n"])));
export var NavMenuMobileItemListStyled = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  padding-inline-start: 0;\n"], ["\n  margin: 0;\n  padding: 0;\n  padding-inline-start: 0;\n"])));
export var NavMenuMobileItemStyled = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  list-style: none;\n  border-bottom: ", " solid ", ";\n"], ["\n  list-style: none;\n  border-bottom: ", " solid ", ";\n"])), Border.Width, Color.PrimaryLight);
var templateObject_1, templateObject_2, templateObject_3;
