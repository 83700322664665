import React, { useCallback, useState } from 'react';
export var useStorage = function (key) {
    var _a = useState(function () {
        return JSON.parse(localStorage.getItem(key));
    }), value = _a[0], setValue = _a[1];
    var updateValue = useCallback(function (newValue) {
        localStorage.setItem(key, JSON.stringify(newValue));
        setValue(newValue);
    }, [key]);
    var returnValue = React.useMemo(function () { return ({
        value: value,
        updateValue: updateValue,
    }); }, [updateValue, value]);
    return returnValue;
};
