var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight, LineHeight, MediaQuery, Spacing } from '@atomic/obj.constants';
export var H1Display = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n\n  margin-block: 0;\n\n  ", " {\n    font-size: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n\n  margin-block: 0;\n\n  ", " {\n    font-size: ", ";\n  }\n"])), Color.GrayXDark, FontSize.XXLarge, FontFamily.Primary, FontWeight.Black, LineHeight.Medium, MediaQuery.lg, FontSize.XXXXLarge);
H1Display.displayName = 'H1Display';
export var H1 = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin-block: 0;\n  margin-bottom: ", ";\n  margin-top: ", ";\n\n  ", " {\n    font-size: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin-block: 0;\n  margin-bottom: ", ";\n  margin-top: ", ";\n\n  ", " {\n    font-size: ", ";\n  }\n"])), Color.GrayXDark, FontSize.XXLarge, FontFamily.Primary, FontWeight.Black, LineHeight.Medium, Spacing.Medium, Spacing.Large, MediaQuery.lg, FontSize.XXXLarge);
export var H2 = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin-bottom: ", ";\n  margin-top: ", ";\n\n  ", " {\n    font-size: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin-bottom: ", ";\n  margin-top: ", ";\n\n  ", " {\n    font-size: ", ";\n  }\n"])), function (props) { return (props.variant === 'light' ? Color.White : Color.GrayXDark); }, FontSize.XLarge, FontFamily.Primary, FontWeight.Bold, LineHeight.Medium, Spacing.Small, Spacing.Large, MediaQuery.lg, FontSize.XXLarge);
H2.displayName = 'H2';
export var H3 = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: 0;\n\n  ", " {\n    font-size: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: 0;\n\n  ", " {\n    font-size: ", ";\n  }\n"])), Color.GrayXDark, FontSize.Large, FontFamily.Primary, FontWeight.Bold, LineHeight.Small, MediaQuery.lg, FontSize.XLarge);
H3.displayName = 'H3';
export var H4 = styled.h4(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n\n  ", " {\n    font-size: ", ";\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n\n  ", " {\n    font-size: ", ";\n  }\n"])), Color.GrayXDark, FontSize.Medium, FontFamily.Primary, FontWeight.Bold, LineHeight.Small, MediaQuery.lg, FontSize.Large);
H4.displayName = 'H4';
export var Body = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  white-space: initial;\n  margin: 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  white-space: initial;\n  margin: 0;\n"])), Color.GrayXDark, FontSize.Medium, FontFamily.Primary, function (_a) {
    var bold = _a.bold;
    return (bold ? FontWeight.Bold : FontWeight.Regular);
}, LineHeight.Large);
Body.displayName = 'Body';
export var BodySecondary = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: 0;\n"])), Color.PrimaryXDark, FontSize.Medium, FontFamily.Primary, function (_a) {
    var bold = _a.bold;
    return (bold ? FontWeight.Bold : FontWeight.Regular);
}, LineHeight.Large);
export var Label = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  white-space: initial;\n  margin: 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  white-space: initial;\n  margin: 0;\n"])), Color.Black, FontSize.Medium, FontFamily.Primary, function (_a) {
    var bold = _a.bold;
    return (bold ? FontWeight.Bold : FontWeight.Medium);
}, LineHeight.Small);
export var InputLabel = styled.label(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"], ["\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-bottom: ", ";\n"])), function (props) { return (props.hasError ? Color.Alert.Dark : Color.GrayXDark); }, FontSize.Medium, FontFamily.Primary, FontWeight.Bold, Spacing.XSmall);
export var InputValueCss = css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"])), Color.Black, FontSize.Medium, FontFamily.Primary, FontWeight.Regular);
export var InputValue = styled.label(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  pointer-events: none;\n\n  ", ";\n"], ["\n  pointer-events: none;\n\n  ", ";\n"])), InputValueCss);
export var InputDisabledCss = css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n\n  /* https://stackoverflow.com/a/45209441/3670829 */\n  -webkit-text-fill-color: ", ";\n  opacity: 1;\n  -webkit-opacity: 1;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n\n  /* https://stackoverflow.com/a/45209441/3670829 */\n  -webkit-text-fill-color: ", ";\n  opacity: 1;\n  -webkit-opacity: 1;\n"])), Color.Gray, FontSize.Medium, FontFamily.Primary, FontWeight.Bold, Color.GrayLight);
export var InputDisabled = styled.label(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), InputDisabledCss);
export var InputCaption = styled.p(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  line-height: ", ";\n  display: block;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  margin-top: ", ";\n  line-height: ", ";\n  display: block;\n"])), Color.Gray, FontSize.Small, FontFamily.Primary, FontWeight.Regular, Spacing.XSmall, LineHeight.Large);
export var InputCaptionError = styled(InputCaption)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), Color.Alert.Dark);
export var InputPlaceholderCss = css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n"])), Color.GrayDark, FontSize.Medium, FontFamily.Primary, FontWeight.Bold);
export var InputPlaceholder = styled.label(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  pointer-events: none;\n  ", ";\n"], ["\n  pointer-events: none;\n  ", ";\n"])), InputPlaceholderCss);
export var DT = styled.dt(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: 0;\n"])), Color.GrayXDark, function (_a) {
    var largeNumber = _a.largeNumber;
    return (largeNumber ? '56px' : FontSize.Medium);
}, FontFamily.Primary, FontWeight.Bold, LineHeight.Large);
export var DD = styled.dd(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: 0;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-family: ", ";\n  font-weight: ", ";\n  line-height: ", ";\n  margin: 0;\n"])), Color.GrayXDark, FontSize.Medium, FontFamily.Primary, FontWeight.Regular, LineHeight.Large);
// Utilities
export var Ellipsed = styled.span(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  max-width: 100%;\n  display: inline-block;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"], ["\n  max-width: 100%;\n  display: inline-block;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
