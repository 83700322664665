import React from 'react';
import { Body, DT } from '@atomic/atm.typography';
import { DefinitionListComponentWrapperStyled } from '@atomic/mol.definition-list-card/definition-list-card.component.style';
import { Separator } from '@atomic/obj.box';
export var VerticalDefinitionListCard = function (props) {
    return (React.createElement(DefinitionListComponentWrapperStyled, null, props.items.map(function (item, index) { return (React.createElement(React.Fragment, { key: "".concat(item.title, "-").concat(item.description) },
        React.createElement(DT, { largeNumber: true }, item.title),
        React.createElement(Body, { as: 'dd' }, item.description),
        index < props.items.length - 1 && React.createElement(Separator, null))); })));
};
