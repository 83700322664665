import * as React from 'react';
import { SplitSectionHalfImageWrapperStyled, SplitSectionHalfStyled, SplitSectionStyled, SplitSectionWrapperStyled, } from '@atomic/mol.section/split-section.component.style';
export var SplitSection = function (_a) {
    var contentFirst = _a.contentFirst, Content = _a.Content, Image = _a.Image;
    return (React.createElement(SplitSectionWrapperStyled, null,
        React.createElement(SplitSectionHalfImageWrapperStyled, { hideOnMediumScreen: contentFirst, mediumScreenJustifyContent: 'flex-start' },
            React.createElement(SplitSectionStyled, null,
                React.createElement(Image, null))),
        React.createElement(SplitSectionHalfStyled, null,
            React.createElement(SplitSectionStyled, null,
                React.createElement(Content, null))),
        contentFirst && (React.createElement(SplitSectionHalfImageWrapperStyled, { hideOnSmallScreen: true, mediumScreenJustifyContent: 'flex-end' },
            React.createElement(SplitSectionStyled, null,
                React.createElement(Image, null))))));
};
