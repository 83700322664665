import * as React from 'react';
import { AppAssets } from '@app/components/atm.app-assets/app-assets';
import { Card } from '@atomic/atm.card/card.component';
import { Body, Label } from '@atomic/atm.typography';
import { Separator } from '@atomic/obj.box';
import { TestimonyCardBodyStyled, TestimonyCardQuotationMarkStyled, TestimonyCardThumbStyled, TestimonyCardWrapperStyled, } from './testimony-card.component.style';
export var TestimonyCard = function (props) {
    var testimony = props.testimony;
    return (React.createElement(TestimonyCardWrapperStyled, null,
        React.createElement(Card, { noPadding: true },
            React.createElement(TestimonyCardBodyStyled, null,
                React.createElement(TestimonyCardQuotationMarkStyled, { src: AppAssets.Image.QuotationMark }),
                React.createElement(Body, null, testimony.text),
                React.createElement(Separator, null),
                React.createElement(Label, null,
                    testimony.name,
                    " - ",
                    testimony.role)),
            testimony.thumb && React.createElement(TestimonyCardThumbStyled, { src: testimony.thumb, alt: '' }))));
};
