import * as React from 'react';
import { matchPath, useNavigate, useLocation } from 'react-router-dom';
export var useNavigation = function () {
    var navigate = useNavigate();
    var goBack = React.useCallback(function () {
        navigate(-1);
    }, [navigate]);
    return { goBack: goBack };
};
export var useMatch = function () {
    var pathname = useLocation().pathname;
    var match = React.useCallback(function (path) {
        return !!matchPath(path, pathname);
    }, [pathname]);
    return { match: match };
};
export var usePath = function () {
    var pathname = useLocation().pathname;
    var addToPath = React.useCallback(function (param) {
        return pathname + param;
    }, [pathname]);
    return { addToPath: addToPath };
};
